@import 'src/assets/css/variables.scss';

.collapseContainer {
  margin-bottom: 18px;
  .collapseHeader {
    width: 100%;
    height: 81px;
    background: $white;
    box-shadow: 0 2px 6px #e5e5e5;
    ul {
      width: 70%;
      height: 100%;
      float: left;
      margin: 0;
      padding: 28px 15px;

      li {
        width: 25%;
        float: left;
        display: inline-block;
        padding-right: 18px;
        span {
          font-weight: 600;
        }
      }

      li:first-child {
        padding-left: 0;
      }

      li:last-child {
        padding-right: 0;
      }
    }

    .collapseIcon {
      float: right;
      width: 30%;

      span {
        display: block;
        cursor: pointer;
        float: right;
        padding: 31px 18px;
      }
    }
  }
  .collapseInnerContent {
    width: 100%;
    height: 280px;
    transition: all 0.35s ease-out;
    display: block;
    background: #ffff;
    border-top: 0.3px solid #a3a6b3;
    position: relative;
    &.collapsed {
      height: 0;
      transition: all 0.35s ease-out;
    }
    .formContent {
      display: block;
      &.collapsed {
        display: none;
      }
    }
    .row {
      margin: 32px 0 50px;
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      background: #ffffff;
      li {
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        width: 27.5%;
        .inputLabel {
          font-family: Roboto, Serif;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #323335;
          padding-bottom: 18px;
          line-height: 110.69%;
        }
        .windowColumn {
          div {
            margin: 0;
            float: left;
            padding-right: 18px;
            span {
              padding-right: 12px;
              line-height: 40px;
              height: 40px;
            }
          }
        }
        input {
          background: #f5f6fa;
          border-radius: 3px;
          font-family: Roboto, serif;
          font-size: 14px;
          line-height: 10px;
          color: #323335;
          border: 0;
          box-shadow: none;
          padding: 15px 0 15px 10px;
          height: 40px;
          outline: none;
          &:active {
            outline: none;
          }
          &.density {
            width: 130px;
          }
          &.windowField {
            width: 140px;
          }
          &.dateField {
            width: 100%;
            max-width: 239px;
          }
          &.messageError {
            border: 1px solid $red;
          }
        }
      }
      li:first-child {
        padding-left: 50px;
        width: 45%;
      }
    }

    .rowButtons {
      float: right;
      margin: 18px 0;
      position: absolute;
      bottom: 0;
      right: 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        padding: 5px 10px;
        margin-right: 18px;
        min-width: 115px;
        height: 24px;
        border: none;
        font-family: Roboto, serif;
        font-size: 12px;
        background: $blue;
        border-radius: 3px;
        color: $white;
        cursor: pointer;
      }
      .errorMessage {
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        padding: 0 25px;
        color: #f44336;
      }
      .saveButton {
        margin-right: 18px;

        &:hover {
          background-color: #004c8a;
          color: #e0f0f6;
        }
      }

      .cancelButton {
        color: $blue;
        border: 2px solid $blue;
        background: $white;
      }
    }
  }
  .deleteBacContent {
    height: 150px;
    h1 {
      display: block;
      text-align: center;
    }
  }
}

/* Hide the browser's default radio button */
.container {
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  font-weight: 600;
  &.densityButtons {
    width: 50%;
    float: left;
    padding-bottom: 19px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #a3a6b3;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #a3a6b3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #a3a6b3;
}
