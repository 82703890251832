@import 'src/assets/css/variables';

.rdrDefinedRangesWrapper {
  width: 107px;
}
.rdrStaticRanges {
  border-right: 0.5px solid $dark_grey;
}
.rdrInputRanges {
  border-right: 0.5px solid $dark_grey;
}
.rdrStaticRange {
  &.rdrStaticRangeSelected {
    background: $blue;
    font-weight: 500;
    span {
      color: $white;
    }
  }
  .rdrStaticRangeLabel {
    &:hover {
      background: $blue;
      color: $white;
    }
  }
  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $blue;
    padding: 13px 17px;
    &:hover {
      background: $blue;
      color: $white;
    }
  }
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  width: 30px;
  margin-left: 8px;
}

.rdrDayInPreview,
.rdrDayStartPreview {
  border: 1px solid transparent;
}

.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  display: none;
}
