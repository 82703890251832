.dropdownContent {
  position: absolute;
  z-index: 999999;
  background: #ffffff;
  border: 1px solid #a3a6b3;
  box-sizing: border-box;
  border-radius: 3px;
  &.filter {
    width: 100%;
  }
  &.standard {
    transform: translateX(-80%);
    width: 140px;
    overflow: auto;
  }
  ul {
    padding: 0;
    text-align: left;
  }
  li {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 29px;
    list-style-type: none;
    height: 29px;
    /* Dark Grey */
    color: #323335;
    padding-left: 10px;
    padding-right: 8px;
  }
  li:hover {
    background: #417eb9;
    color: white;
    cursor: pointer;
  }
}

.outerContainer {
  position: relative;
}
