/*Variables*/

/*Colors*/
$blue: #417eb9;
$navy: #355873;
$purple: #4c4573;
$red: #f44336;
$dark_grey: #323335;
$medium_grey: #a3a6b3;
$light_grey: #f5f6fa;
$black: #000;
$white: #fff;

$stable: #6fcf97;
$risk: #efbb55;
$critical: #ed6f6a;

/* Screen Sizes */
$app_static_width: 100%;

/*Others*/
$box-shadow-default: #e5e5e5;
