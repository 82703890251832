@mixin active-label($gradient: null) {
  font-weight: 700;
  position: relative;
  color: #000;
  border: solid 2px transparent;
  line-height: 7px;
  &:before {
    background: $gradient;
    box-shadow: 0 2px 6px #e5e5e5;
    content: '';
    bottom: -2px;
    position: absolute;
    height: 2px;
    width: 100%;
  }
}

.patient-detail-card-container {
  margin-bottom: 18px;
  .patient-detail-card-status-card {
    width: 267px;
    height: 49px;
    box-shadow: 0px 2px 6px #e5e5e5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 18px;

    span {
      margin-left: 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
    }
  }

  .patient-detail-card-profile-card {
    width: 267px;
    height: 583px;
    background: #ffffff;
    box-shadow: 0px 2px 6px #e5e5e5;

    .patient-detail-card-top-row {
      padding: 18px 18px 0px 18px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        line-height: 16px;
        color: #417eb9;
        cursor: pointer;
      }
      svg {
        height: 82px;
        width: 82px;
      }
    }
    .patient-detail-card-middle-row {
      margin-top: 32px;
      height: 147px;
      border-bottom: 0.3px solid #a3a6b3;
      width: 100%;
      .progress-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        width: 100%;
        .progress-container-top-row {
          margin-left: 18px;
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          align-items: center;
          width: 100%;
          margin-right: 18px;
          .progress-container-top-row-number {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            color: #323335;
          }
          .progress-container-top-row-time {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #a3a6b3;
            margin-left: 12px;
          }
          .progress-container-top-row-name {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #323335;
            margin-left: auto;
          }
          svg {
            margin-left: 12px;
          }
        }
        .progress-container-bottom-row {
          border-radius: 100px;
          margin-bottom: 4px;
          width: 231px;
          height: 6px;
          position: relative;
          margin-left: 18px;
          .progress-container-bottom-row-gray {
            background-color: #f5f6fa;
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }
        .progress-container-score-label {
          margin-bottom: 14px;
          width: 231px;
          height: 14px;
          margin-left: 18px;
          background: #ffffff;
          box-shadow: 0 2px 6px #e5e5e5;
          display: flex;
          border-radius: 10px;
          z-index: 1;
          .score-label-container {
            height: 100%;
            display: flex;
            border-right: 1px solid #a0a0a0;
            flex-direction: row;
            justify-content: space-around;
            &.first {
              width: 29.5%;
              .active {
                @include active-label(linear-gradient(90deg, #d18195 0%, #ea6969 100%));
              }
            }
            &.second {
              width: 30%;
              .active {
                @include active-label(linear-gradient(90deg, #e39253 0%, #f0bf55 100%));
              }
            }
            &.third {
              width: 20%;
              .active {
                @include active-label(linear-gradient(270deg, #6be3ba 0%, #5cc5e2 100%));
              }
            }
            &.fourth {
              width: 20.5%;
              border: none;
              .active {
                @include active-label(linear-gradient(270deg, #6be3ba 0%, #5cc5e2 100%));
              }
            }

            .score-label {
              color: #323335;
              height: 100%;
              line-height: 14px;
              font-size: 11px;
              text-align: center;
              &.small {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    .patient-detail-card-bottom-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 0.3px solid #a3a6b3;
      height: 72px;
      position: relative;
      .patient-detail-card-bottom-row-last {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #323335;
        width: 100%;
        margin-left: 18px;
        margin-right: 18px;
        align-self: center;
        margin-top: 11px;
      }
      .patient-detail-card-bottom-row-by {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #323335;
        width: 100%;
        margin-left: 18px;
        margin-right: 18px;
        align-self: center;
        margin-bottom: 11px;
      }
    }
    .patient-detail-card-connected-row {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 75px;
      padding: 12px;
      color: #323335;
      gap: 5px;
      border-bottom: 0.3px solid #a3a6b3;
      .patient-device-info {
        width: 100%; 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .patient-detail-card-device-worn {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        .worn-icon {
          align-items: center;
          display: flex;
        }
        span {
          img,
          svg {
            margin-left: 8px;
            height: 20px;
            width: 17px;
          }
        }
      }
      .patient-detail-card-percentage {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        width: 100%;
        &.flex-end {
          justify-content: flex-end;
        }
        span {
          display: inline-flex;
          align-items: flex-end;
          img {
            height: 20px;
            margin-right: 8px;
          }
          svg {
            margin-left: 8px;
            height: 17px;
            width: 15px;
          }
        }
      }
    }
    .patient-detail-card-name {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
      display: flex;
      align-items: center;
      color: #323335;
      margin: 21px 18px 12px 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .patient-detail-card-encounter {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 110.69%;
      display: flex;
      align-items: center;
      color: #323335;
      text-transform: uppercase;
      margin: 0 18px;
    }
  }
  .insight-card {
    width: 267px;
    height: 93px;
    background: #ffffff;
    box-shadow: 0px 2px 6px #e5e5e5;
    margin-top: 18px;
    padding: 18px;

    .insight-card-top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .insight-card-title {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #323335;
      }
      .tooltip-container {
        margin-left: 4px;
        line-height: 8px;
      }
      .insight-tooltip {
        width: 440px;
        top: -140px;
        font-size: 12px;
        line-height: 14px;
        padding: unset;
        .hover-text {
          background: #f5f6fa;
          width: inherit;
          height: inherit;
          padding: 14px 17px 14px 20px;
          color: #444444;
        }
      }
      .insight-card-time {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #a3a6b3;
      }
    }
    .insight-card-bottom-row {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .insight-card-value {
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #323335;
      }
      .insight-card-bottom-icon {
        margin-left: 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        flex-direction: column;
        color: #323335;
        align-items: center;

        svg {
          margin-top: 4px;
        }

        &__no-unit {
          svg {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .logo-image {
    width: 267px;
    height: 93px;
    margin: 18px 0;
    padding: 0 18px;
  }
}


