@import '../../../../assets/css/variables';

.locationModal {
  margin: 0 auto;
  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;
    h4 {
      margin: 0 auto;
      text-transform: uppercase;
      color: $dark_grey;
      font-size: 18px;
      line-height: 12px;
      padding: 30px 32px;
      font-weight: bold;
    }
  }

  .locationDetail {
    height: 50px;
    border-bottom: 0.3px solid #a3a6b3;
    width: 100%;
    margin: 0 auto;
    padding: 18px 18px;
    .box {
      font-family: 'Roboto', serif;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      color: $dark_grey;
      padding: 0 18px;
      float: left;
      text-align: right;
      width: 20%;
      span {
        font-weight: bold;
      }
    }
    .box:first-child {
      width: 40%;
      text-align: left;
    }
  }

  .mapContainer {
    height: 600px;
    min-height: 400px;
  }
}
