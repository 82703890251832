@import '../../../assets/css/variables';
.questionAnswers {
  margin-top: 16px;
}
.selectedAnswer {
  background: #f5f6fa;
  border-radius: 10px;
  color: #355873;
  display: inline-block;
  padding: 5px 13px;
}

.questionCell {
  margin-top: 35px;
}
.mainQuestion {
  font-weight: bold;
}
.scoreLabel {
  font-weight: bold;
}
.subQuestionCell {
  margin-top: 16px;
}
