@import 'src/assets/css/variables';

.chartToolTipReverse {
  background-color: #fff;
  height: 331px;
  width: 634.33px;
  border-top: 1px solid #a3a6b3;
  border-left: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 25px;
  margin-top: -32px;
  position: absolute;
  bottom: 20%;
  right: 0;
  .btnClose {
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    background-size: 20px 20px;
    background: url('../../../assets/icons/close-modal.svg') no-repeat center center;
  }
  .btnClose img {
    width: 100%;
  }
}

.chartToolTipReverse:before {
  right: -20px;
  top: 14%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: $medium_grey;
  border-left-color: #a3a6b3;
  border-width: 165.5px;
  margin-top: -47.25px;
  clip-path: polygon(94% 0, 94% 0, 100% 50%, 94% 100%);
}

.chartToolTipReverse:after {
  right: -20px;
  top: 14%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #ffffff;
  border-left-color: #fff;
  border-width: 165.5px;
  margin-top: -46.25px;
  clip-path: polygon(94% 0, 94% 0, 100% 50%, 94% 100%);
}

.chartToolTip {
  background-color: #fff;
  height: 331px;
  width: 634.33px;
  border-top: 1px solid #a3a6b3;
  border-right: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: -32px;
  position: absolute;
  bottom: 20%;

  .btnClose {
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    background-size: 20px 20px;
    background: url('../../../assets/icons/close-modal.svg') no-repeat center center;
  }
  .btnClose img {
    width: 100%;
  }
}

.chartToolTip:before {
  left: -20px;
  top: 14%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: $medium_grey;
  border-right-color: #a3a6b3;
  border-width: 165.5px;
  margin-top: -47.25px;
  clip-path: polygon(6% 0, 6% 0, 6% 100%, 6% 100%, 0% 50%);
}

.chartToolTip:after {
  left: -20px;
  top: 14%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: white;
  border-right-color: #fff;
  border-width: 165.5px;
  margin-top: -46.25px;
  clip-path: polygon(6% 0, 6% 0, 6% 100%, 6% 100%, 0% 50%);
}

.insightContent {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 0 25px;
  cursor: grab;
  touch-action: none;

  &.dragging {
    z-index: 1;
    transition: none;

    * {
      cursor: grabbing;
    }
  }

  .insightDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    border-top: 0.3px solid #a3a6b3;
    h4 {
      font-size: 12px;
      line-height: 14px;
      color: $dark_grey;
      margin: 0;
      font-weight: 300;
      span {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .pagination {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      .prevArrow {
        display: flex;
        align-items: center;
        margin-right: 9px;
        cursor: pointer;
        i {
          background: url('../../../assets/icons/icn_carat_large.svg') no-repeat center center;
          height: 12px;
          width: 9px;
          display: block;
          transform: rotate(180deg);
        }
      }
      .nextArrow {
        display: flex;
        align-items: center;
        margin-left: 9px;
        cursor: pointer;
        i {
          background: url('../../../assets/icons/icn_carat_large.svg') no-repeat center center;
          height: 12px;
          width: 9px;
          display: block;
        }
      }
    }
  }
  .snapshotTitle {
    height: 107px;
    padding-bottom: 10px;
    h1 {
      margin: 0px 0px 10px 0px;
      font-size: 18px;
      line-height: 28px;
      color: $dark_grey;
      text-transform: uppercase;
    }

    span {
      font-size: 14px;
      line-height: 110%;
      color: $dark_grey;
      min-height: 63px;
      display: block;
    }
  }

  .snapshot {
    height: 73px;
    padding-bottom: 10px;
    h3 {
      margin: 0px 0px 1px 0px;
      font-size: 14px;
      line-height: 16px;
      color: $dark_grey;
      text-transform: uppercase;
    }
  }
}

.hoverTooltip {
  background-color: #fff;
  height: 80.5px;
  width: 159px;
  padding: 5px;
  border-top: 1px solid #a3a6b3;
  border-right: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 60px;
  margin-top: -32px;
  z-index: 9999;

  &:before {
    right: 100%;
    top: 59%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 128, 0, 0);
    border-right-color: #a3a6b3;
    border-width: 40.25px;
    margin-top: -47.25px;
  }
  &:after {
    right: 100%;
    top: 59%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 128, 0, 0);
    border-right-color: #fff;
    border-width: 39.25px;
    margin-top: -46.25px;
  }
  .hoverTooltipCTA {
    color: #a3a6b3;
    line-height: 14px;
    font-size: 12px;
    display: flex;
    padding-top: 5px;
  }
  .hoverTooltipTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #323335;
    border: 1px solid #ffffff;
  }

  .hoverTooltipDate {
    font-size: 12px;
    line-height: 14px;
    color: #323335;
  }
}
