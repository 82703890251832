@import 'src/assets/css/variables';

.inputWrapper {
  display: inline-block;
  position: relative;
}
.searchInput {
  width: 288px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid $blue;
  font-size: 12px;
  line-height: 14px;
  padding-left: 30px;
  background: url('../../../assets/icons/icn_search.svg') no-repeat left 0.7em center;
  &::placeholder {
    color: $medium_grey;
  }
  &:focus {
    outline: none;
  }
}

.searchDelete {
  position: absolute;
  right: 6px;
  cursor: pointer;
}
