.tableRow {
  height: 65px;
  background: #ffffff;
  color: #323335;
  td {
    border-bottom: 0.5px solid #a3a6b3;
    .align-icon {
      display: flex;
    }
  }
}
.nameTreat {
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.name {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 200px;
  color: #323335;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.action {
    color: #417eb9;
    cursor: pointer;
    &:hover {
      color: #4c4573;
    }
  }
}
.treatment {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  /* Dark Grey */
  color: #323335;
}
.insight {
  text-align: start;
  height: 65px;
  font-weight: 500;
  font-size: 12px;
  /* At Risk */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;

  div {
    width: 10px;
    height: 10px;
    margin-right: 7px;
  }
  svg {
    margin-right: 7px;
  }
  span {
    color: #333333;
  }
}

.connected {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #323335;

  span {
    margin-left: 6px;
  }
}

.heart-rate {
  .score-wrapper {
    display: flex;

    .score {
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      color: #323335;
      min-width: 30px;
    }

    .bpm {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #323335;
      }
    }
  }
}

.action {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  padding-right: 18px;
  color: #417eb9;
  cursor: pointer;

  span {
    float: right;
  }
}

.engagement {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #323335;
}
.viviScore {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #323335;
  span {
    margin-bottom: 6px;
  }
}
.viewedBy {
  .name {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    height: 18px;
    display: block;
  }
  .date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #444444;
  }
}

.clientName {
  display: flex;
  align-items: center;
  height: 65px;
}

.nameDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 11.52px;
}

.bar-wrapper {
  border-radius: 100px;
  margin-bottom: 18px;
  width: 115px;
  height: 6px;
  position: relative;

  .static-bar {
    background-color: #f5f6fa;
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }

  .result-bar {
    border-radius: 100px;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

.profileImage {
  margin-left: 10px;
  position: relative;
  .phoneStatusIcon {
    position: absolute;
    top: 70%;
    right: 0;
  }
}

.relapseText {
  font-size: 24px;
  color: #323335;
  line-height: 28px;
}
.relapseIcon {
  padding-left: 14px;
}
.iconBackgroundWrapper {
  width: 27px;
  height: 27px;
  background: rgba(196, 196, 196, 0.1);
  border-radius: 2px;
  &.relapse {
    padding: 6px;
    line-height: 1px;
  }
}
.complianceRow {
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  .iconBox {
    width: 27px;
    height: 27px;
    margin: 0 2.5px;
    border-radius: 2px;
    background: rgba(196, 196, 196, 0.1);
    .iconBackground {
      padding: 3px;
      width: 100%;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
}

.complianceHoverBox {
  position: absolute;
  left: 125px;
  top: -30px;
  padding: 10px 10px 10px 20px;
  z-index: 999;
  background-color: #ffffff;
  filter: drop-shadow(0 5px 10px #a3a6b3);
  width: 215px;
  height: 95px;

  .complianceHoverBoxLeft {
    position: absolute;
    bottom: 0px;
    left: -14px;
    width: 30px;
    height: 100%;
    clip-path: polygon(50% 0%, 0 50%, 50% 100%);
    background-color: #ffffff;
  }
  .violationRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    .badge {
      display: flex;
      max-width: 49px;
      height: 18px;
      background: #6fcf97;
      border-radius: 12px;
      margin-right: 10px;
      padding-left: 5px;
      padding-right: 5px;
      color: #ffffff;
      align-items: center;
      svg {
        circle {
          stroke: #ffffff;
        }
        path {
          stroke: #ffffff;
          fill: #ffffff;
        }
      }
      &.alert {
        background: #ed6f6a;
      }
    }
    .title {
      font-size: 12px;
      color: #a3a6b3;
    }
  }
}
