@import '../../../../assets/css/variables';
@import 'upsert-geofence-form.module';

.confirmContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .mainContent {
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 700px;
    margin: 0 auto;
    height: auto;
    flex-direction: column;
    p {
      display: flex;
      justify-content: center;
      align-content: center;
      color: $dark_grey;
      font-size: 16px;
    }
    .mainText {
      max-width: 700px;
      margin: 0 auto;
      color: $dark_grey;
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      text-align: center;
    }
    .geofenceNameText {
      background: $light_grey;
      padding: 5px 11px;
      border-radius: 10px;
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      color: $navy;
      font-family: Roboto, sans-serif;
      margin-right: 11px;
    }
    .smallText {
      font-weight: normal;
      font-size: 16px;
      display: flex;
      justify-content: center;
      color: $medium_grey;
      margin: 15px auto;
    }
  }
}
