.deleteBtn {
  border: 0;
  background: transparent;
  outline: 0;
}
.viewButton {
  color: #417eb9;
  background: transparent;
  border: 0;
  outline: 0;
  border-radius: 0;
}
