@import 'src/assets/css/variables';

.verifyModal {
  .header {
    padding: 32px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    border-bottom: 0.3px solid $medium_grey;
  }

  .form {
    margin: 32px;
    height: 500px;

    p {
      margin-top: 15px;
      width: 330px;
      font-size: 12px;
      line-height: 13px;
      color: $medium_grey;
    }

    .errorMessage {
      color: $critical;
    }
  }

  .buttons {
    position: absolute;
    bottom: 32px;
    padding: 0px 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      justify-content: flex-end;
    }

    button {
      min-width: 115px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }

    .blueBtn {
      background: $blue;
      border: none;
      color: $white;
    }

    .whiteBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      margin: 0px 12px;
    }

    .disabledBtn {
      border: none;
      cursor: default;
      background: $medium_grey;
      color: $white;

      &__sideMargin {
        margin: 0px 12px;
        border: none;
        cursor: default;
        background: $medium_grey;
        color: $white;
      }
    }
  }
}
