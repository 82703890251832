@import '../../../assets/css/variables';
@import 'src/assets/css/datepicker-base';

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
}
.monthName {
  display: none;
}
.monthAndYearText {
  font-family: Roboto, serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.08px;
  text-transform: uppercase;
  color: #417eb9;
}
.nextButton {
  background: transparent;
  border: none;
  i {
    background: url('../../../assets/icons/icn_carat_large.svg') no-repeat center center;
    height: 13px;
    width: 13px;
    display: block;
    margin: 0 auto;
    border-style: none;
  }
}
.prevButton {
  background: transparent;
  border: none;
  i {
    background: url('../../../assets/icons/icn_carat_large.svg') no-repeat center center;
    height: 13px;
    width: 13px;
    display: block;
    margin: 0 auto;
    border-style: none;
    transform: rotate(180deg);
  }
}

.weekDays {
  border-bottom: 0.5px solid #323335;
  span {
    font-weight: 600;
    text-transform: uppercase;
    color: #323335;
    font-size: 16px;
  }
}
.staticRangeLabel {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: $blue;
  padding: 10px 17px;
}
.staticRange {
  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $blue;
    padding: 10px 17px;
  }
}

.staticRanges {
  border-right: 0.5px solid $dark_grey;
}
.inputRanges {
  border-right: 0.5px solid $dark_grey;
}

.yearPicker {
  select {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: $blue;
    border-radius: 3px;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-image: url('../../../assets/icons/arrow-down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
  }
}

.monthPicker {
  select {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: $blue;
    border-radius: 3px;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-image: url('../../../assets/icons/arrow-down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
  }
}

.selected {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 6px;
  margin-top: -2px;
}
