.awareTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    font-size: 16px;
}

.paragraph {
    margin-top: 25px;

    &.indent {
        margin-left: 25px;
    }

    .text {
        font-size: 16px;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.left {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.textSpacing {
    letter-spacing: 10px;
}

.tableTitle {
    font-size: 16px;
    font-weight: bolder;
    margin-left: 10px;
}

table,
td,
th {
    border: 1px solid;
}

td,
th {
    padding-left: 5px;
}

table {
    border-collapse: collapse;
}
.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.reference {
    display: inline-block;
    margin-left: 20px;
}
.bold {
    font-weight: bold;
}

.answerTable {
    margin-top: 25px;
     width: 70%;
}

.height50 {
    height: 50px;
}

.width50 {
    width: 50px;
}
.width100 {
    width: 100px;
}
.width200 {
    width: 200px;
}
.textCenter {
    text-align: center;
}

.selectedAnswerAware {
    -moz-border-radius: 50px/50px;
    -webkit-border-radius: 50px 50px;
    border-radius: 50px/50px;
    border: solid 2px #01B050;
    width: 50px;
    min-height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
}

.left50 {
    margin-left: 50px;
}
.answerNumber {
    font-size: 16px;
}
.answer {
    margin-left: 10px;
    border-bottom: 1px solid black;
    display:block;
    width: 50px;
    font-size: 28px;
    text-align: center;
}

.regSum {
    margin-left: 20px;
    font-size: 28px;
    border-bottom: 1px solid black;
    width: 50px;
}
.plus {
    margin-left: 100px;
    font-size: 28px;
}
.invertedSum {
    margin-left: 100px;
    font-size: 28px;
    border-bottom: 1px solid black;
    width: 50px;
}
.equal {
    margin-left: 50px;
    font-size: 28px; 
}
.answerValue {
    margin-left: 50px;
    font-size: 28px; 
    border-bottom: 1px solid black;
    width: 100px;
    text-align: center;
}