.chartContainer{
  .header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    width: 730px;
    justify-content: center;
    color: #323335;
    margin: 40px 0;
  }
}