@import '../../../../assets/css/variables';

.form {
  margin: 0 auto;
  .mapContainer {
    height: 500px;
    width: 100%;
    border-bottom: 0.3px solid $medium_grey;
  }

  .body {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.modalHeader {
  border-bottom: 0.3px solid $medium_grey;

  h4 {
    margin: 0 auto;
    text-transform: uppercase;
    color: $dark_grey;
    font-size: 18px;
    line-height: 12px;
    padding: 30px 32px;
    font-weight: bold;
  }
}
.rowButtons {
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin: 0 12px 32px 0;
    width: 115px;
    height: 24px;
    border: none;
    font-family: Roboto, serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    background: $blue;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
  }
  .deleteButton {
    margin-left: 32px;
    color: $blue;
    border: 2px solid $blue;
    background: $white;
  }

  .saveButton {
    text-align: center;
    margin-right: 32px;

    &:hover {
      background-color: #004c8a;
      color: #e0f0f6;
    }
  }

  .cancelButton {
    margin-left: auto;
    color: $blue;
    border: 2px solid $blue;
    background: $white;
  }
}
