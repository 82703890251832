@import '../../../../assets/css/variables';

.locationModal {
  margin: 0 auto;
  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;
    h4 {
      margin: 0 auto;
      text-transform: uppercase;
      color: $dark_grey;
      font-size: 18px;
      line-height: 12px;
      padding: 30px 32px;
      font-weight: bold;
    }
  }

  .locationDetail {
    height: 50px;
    border-bottom: 0.3px solid #a3a6b3;
    .box {
      font-family: Roboto, serif;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      color: $dark_grey;
      padding: 17px 87px 17px 0;
      float: left;
      //width: 25%;
      span {
        font-weight: bold;
      }
    }
    .box:first-child {
      padding-left: 32px;
    }
  }

  .mapContainer {
    height: 600px;
    min-height: 400px;
  }
}
