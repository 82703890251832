@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 34px;
  button.generate {
    background: #417eb9;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    border: 0;
    width: 124px;
    height: 24px;
    margin-left: 18px;
    cursor: pointer;
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
  .rdrDefinedRangesWrapper div {
    background: #2f2a30 !important;
  }
}
.dropdownButton {
  cursor: pointer;
  border: 1px solid #417eb9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 12px;
  align-items: center;
  color: #417eb9;
  .arrow {
    margin-left: 13px;
  }
}
