.container {
  background-color: #f5f6fa;
  min-height: 100vh;
  width: 100%;
  .content {
    margin: 0 18px 0 18px;
  }
  .menuRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 83px;
    padding-top: 33px;
  }
}
