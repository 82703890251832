@import '../../../assets/css/variables';
.bacTestRequestModal {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  min-height: 80vh;
  width: 100%;

  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 70px;
    .patientInfoRow {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      align-items: flex-end;
      h5 {
        display: inline;
        color: $dark_grey;
        font-size: 12px;
        margin: 5px;

      }
    }

    h4 {
      text-transform: uppercase;
      color: $dark_grey;
      font-size: 18px;
      line-height: 20px;
      padding: 30px 32px;
      font-weight: bold;
      display: inline;
      margin: 0;
    }

  }
  .bacTestRequestContainer {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    .bacImage {
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 50px 0;
    }
    .mainText {
      display: flex;
      flex-direction: column;
      p {
        font-weight: 600;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #323335;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 750px;
        margin: 0 auto;
      }
      h3 {
        margin: 64px 0;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #323335;
      }
    }
  }
  .rowButtons {
    display: flex;
    justify-content: flex-end;
    margin: 32px 0;

    .saveButton,
    .cancelButton {
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      padding: 5px 25px;
      margin-right: 18px;
      min-width: 115px;
      height: 24px;
      border: none;
      font-family: Roboto, serif;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      background: $blue;
      border-radius: 3px;
      color: $white;
      cursor: pointer;
    }

    .saveButton {
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 32px;

      &:hover {
        background-color: #004c8a;
        color: #e0f0f6;
      }
    }

    .cancelButton {
      color: $blue;
      border: 2px solid $blue;
      background: $white;
    }
  }
}
.plus,
.minus {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background: #004c8a;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0 5px;
}

.dropbox {
  display: flex;
  justify-content: center;
}

.questions {
  display: grid;
  grid-template-columns: auto 120px 120px;
  column-gap: 64px;

  overflow: auto;
  flex: 1;
  padding: 0px 32px;
  padding-bottom: 35px;
}

.modalSubHeader {
  display: flex;
  border-bottom: 0.3px solid #a3a6b3;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 32px;
  height: 45px;
  line-height: 45px;
  align-items: center;
  div {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.didSurvey {
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;

    margin-right: 5px;
  }
}

.scroll {
  overflow: scroll;
}

.none {
  overflow: visible;
}