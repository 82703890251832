@import 'src/assets/css/variables';

.readmitModal {
  min-height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__loading {
    min-height: 481px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
    border-bottom: 0.3px solid $medium_grey;
  }

  .body {
    margin-top: 40px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;

    .confirmationText {
      color: $dark_grey;
      font-size: 30px;
      line-height: 110%;
      font-weight: 700;
      margin-bottom: 32px;
    }

    .outerSelect {
      position: relative;
      margin-bottom: 32px;
      display: flex;

      .helpNote {
        font-size: 18px;
        line-height: 110%;
        color: $medium_grey;
        max-width: 650px;
        margin-left: 10px;
        margin-top: 5px;
      }

      .customSelect {
        padding: 2px 12px;
        height: 29px;
        width: 324px;
        background: $white;
        color: $blue;
        border-radius: 3px;
        border: 1px solid $blue;
        font-size: 12px;
        background-image: url('../../assets/icons/arrow-down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        cursor: pointer;
      }

      .customSelectOptions {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 29px;
        width: 324px;
        border: 1px solid $medium_grey;
        border-radius: 3px;
        z-index: 99999;

        .customOption {
          height: 29px;
          padding: 2px 12px;
          font-size: 12px;
          background: $white;
          color: $dark_grey;

          &:hover {
            background: $blue;
            color: $white;
          }

          &:focus {
            background: $blue;
            color: $white;
          }
        }
      }

      .errorText {
        color: $critical;
        font-size: 12px;
        line-height: 110%;
      }
    }
  }
  .buttons {
    align-self: flex-end;
    margin-bottom: 32px;
    margin-right: 32px;

    button {
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      height: 24px;
      min-height: 24px;
    }

    .closeBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      width: 115px;
      min-width: 115px;
    }

    .submitBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;
      width: 132px;
      min-width: 132px;

      &:disabled {
        background: $medium_grey;
        cursor: not-allowed;
      }

      &:hover {
        background-color: #004c8a;
        color: #e0f0f6;
      }
    }
  }
}
