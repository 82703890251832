@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.addBtn {
  margin: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 134px;
  text-decoration: none;
  border: none;
  outline: none;
  height: 24px;
  background: $blue;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  color: $white;
  float: right;
  cursor: pointer;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -4px;
  width: 100%;
}

.profilePicture {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

.picturePlaceholder {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: $light_grey;
  margin-right: 5px;

  &__big {
    width: 41px;
    height: 41px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background-color: $light_grey;
    margin-right: 17px;
  }

  &__loaded {
    width: 41px;
    height: 41px;
    max-height: 41px;
    max-width: 41px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 17px;
  }
}

.form {
  height: 520px;
  overflow-y: scroll;
}

.form__large {
  height: 707px;
}

.form,
.form__large {
  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
    border-bottom: 0.3px solid $medium_grey;

    &__edit {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      padding: 32px 32px 40px 32px;
      border-bottom: 0.3px solid $medium_grey;

      .viewSelection {
        position: absolute;
        top: 61px;

        span {
          font-size: 14px;
          line-height: 16px;
          margin-right: 25px;
          padding-bottom: 10px;
          cursor: pointer;
          outline: none;
          &:hover {
            color: $blue;
            border-bottom: 2px solid $blue;
          }
        }

        .active {
          color: $blue;
          border-bottom: 2px solid $blue;
        }

        .inactive {
          color: $medium_grey;
        }
      }
    }
  }

  .body {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .checkboxGroup {
      .groupName {
        font-size: 14px;
        line-height: 110%;
        display: block;
        margin-bottom: 20px;
        span {
          margin-left: 4px;
        }
      }

      label {
        margin-right: 30px;
      }
    }
  }

  .bottomButtons {
    display: flex;
    justify-content: space-between;
    padding: 0px 32px;
    position: absolute;
    width: 100%;
    bottom: 32px;

    button {
      min-width: 115px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }

    .rightButtons {
      display: flex;

      .submitBtn {
        background: $blue;
        border: none;
        color: $white;
        margin-left: 12px;
        &:hover {
          background: #004c8a;
          color: $white;
        }
        &:disabled {
          background-color: $medium_grey;
          color: $white;
          cursor: not-allowed;
        }
      }

      .closeBtn {
        background: $white;
        border: 2px solid $blue;
        color: $blue;
      }
    }

    .deleteBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;

      &__hide {
        background: $white;
        border: 2px solid $blue;
        color: $blue;
        opacity: 0;
      }
    }
  }

  .uploadFilePlaceholder {
    display: flex;
    align-items: center;
  }
}

.helperAnchor {
  position: relative;
  width: 20px;
  height: 20px;
  top: -16px;
  left: 40px;
  padding-left: 4px;
  cursor: pointer;

  .helper {
    height: 10px;
    width: 10px;
    border: 0.5px solid $blue;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;

    img {
      width: 4px;
      height: 8px;
    }
  }
}

.errorText {
  color: $critical;
  font-size: 12px;
  line-height: 110%;

  &__checkbox {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
    opacity: 0;
  }

  &__checkbox__show {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
  }
}

.loadingModal {
  height: 481px;
  display: flex;
  align-items: center;
}

.rolesTooltip {
  width: 678px;
  height: 410px;
  font-size: 12px;
  line-height: 14px;
  top: -442px;
  left: -198px;
  padding-top: 25px;

  .roleDescription {
    margin-bottom: 13px;

    .title {
      font-weight: 700;
    }
  }
}
