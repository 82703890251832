.admin-container {
  background-color: #f5f6fa;
  min-height: calc(100vh - 65px);

  nav.main-nav {
    height: 41px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .back-link {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-right: 145px;
      /* Blue */

      color: #417eb9;
      svg {
        margin-right: 10px;
      }
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      margin-right: 79px;
      align-items: center;
      height: 41px;

      /* Blue */

      color: #a3a6b3;
      &.active {
        color: #417eb9;
        border-bottom: 2px solid #417eb9;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
}
