.rowContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 700px;
  min-height: 170px;
  .horizontalLine {
    width: 100%;
    border-bottom: 0.3px solid #a3a6b3;
  }
  .header {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #323335;
  }
  .row {
    height: 50px;
    display: flex;
    .cell {
      min-width: 100px;
      font-size: 14px;
      line-height: 14px;
      color: #323335;
      height: 100%;
      padding: 15px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
    .cell:first-child {
      min-width: 200px;
      justify-content: flex-start;
    }
  }
}