@import 'src/assets/css/variables';

.profileImage {
  border-radius: 50%;
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.picturePlaceholder {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: $light_grey;
}
