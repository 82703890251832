.patient-home-container {
  background-color: #f5f6fa;
  min-height: calc(100vh - 65px);

  nav.main-nav {
    height: 41px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #a3a6b3;
    width: 100%;
    .back-link {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      width: 280px;
      margin: 0;
      /* Blue */

      color: #417eb9;
      svg {
        margin-right: 10px;
      }
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      margin-right: 92px;
      align-items: center;
      height: 41px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      /* Blue */

      color: #a3a6b3;
      &.active {
        color: #417eb9;
        border-bottom: 2px solid #417eb9;
      }
      &:last-child {
        margin-right: 18px;
      }
    }
  }
  div.detail-child-container {
    display: flex;
    flex-direction: row;
    .left-container {
      width: 285px;
      display: flex;
      padding-left: 18px;
      padding-top: 18px;

      .top-card {
        background: #ed6f6a;
        /* Card Style */
        width: 287px;
        box-shadow: 0px 2px 6px #e5e5e5;
        height: 49px;
      }
    }
    .right-container {
      padding: 18px;
      width: calc(100% - 285px);
    }
  }
}

.detail-container {
  display: flex;
  flex-direction: column;
  .top-row {
    display: flex;
    flex-direction: row;
    .last-note {
      background: #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 2px 6px #e5e5e5;
      min-width: 410px;
      width: 410px;
      height: 271px;
      margin-right: 18px;

      .top {
        padding: 18px;
        height: 199px;
        border-bottom: 0.3px solid #a3a6b3;
        .panel-title {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #323335;
          text-transform: uppercase;
          display: block;
        }
        .summary {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #323335;
          display: block;
          margin-top: 28px;
        }
        .description {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #323335;
          display: block;
          margin-top: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .read-more {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #417eb9;
          text-transform: uppercase;
          margin-top: 18px;
          display: block;
          cursor: pointer;
        }
      }
      .bottom {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
        font-family: Roboto;
        .left {
          display: flex;
          flex-direction: column;
          .date-label {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #323335;
          }
          .date-value {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #323335;
          }
          .entry-label {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #323335;
          }
          .entry-value {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #323335;
            padding-left: 3px;
          }
        }
        .right {
          button {
            background: #417eb9;
            border-radius: 3px;
            width: 115px;
            height: 24px;
            border: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .chart-container {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    background-color: #ffffff;
    width: 1119px;
    padding: 18px;
    //change these to fix 
    max-height: calc(100vh - 440px);
    height: calc(100vh - 440px);
    min-height: calc(100vh - 440px);

    .charts-container {
      margin-top: 30px;
      max-height: calc(100% - 40px);
      width: 1083px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      //scroling on events chart
      .scrolling-container {
        overflow: scroll;
        width: 1090px;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        .chart-wrapper {
          padding-top: 32px;
          padding-bottom: 32px;
        }
      }
      .scrolling-container::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .insight_line {
        border: 4px dashed #417eb9;
        position: absolute;
        z-index: 999999;
        border-right: 0;
        display: none;
      }
    }

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .left {
        display: flex;
        flex-direction: row;

        & > button {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          outline: none;
          height: 24px;
          width: 73px;
          border: 0;
          margin-right: 14px;
          &.active {
            color: #ffffff;
            background-color: #a3a6b3;
            border-radius: 3px;
          }

          &.inactive {
            color: #a3a6b3;
            background-color: white;
            &:hover {
              background: #a3a6b3;
              border-radius: 3px;
              color: #fff;
            }
          }
        }
      }
      .right {
        .filterDatepicker {
          position: absolute;
          top: 2px;
          right: 0;
          box-shadow: 0 2px 6px #e5e5e5;
          border-radius: 3px;
        }
        .dropdownButton {
          cursor: pointer;
          margin: 0;
          border: 1px solid #417eb9;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 5px 12px;
          align-items: center;
          color: #417eb9;
          .arrow {
            margin-left: 13px;
          }
        }
      }
    }
  }
}

.last-note-modal {
  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #323335;
    height: 77px;
    border-bottom: 0.3px solid #a3a6b3;
    padding: 32px;
  }
  .patient-info {
    border-bottom: 0.3px solid #a3a6b3;
    height: 47px;
    padding: 18px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
    }
  }
  .note-section {
    padding: 32px 32px 0 32px;
    .note-summary {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: #323335;
      margin-bottom: 32px;
      display: block;
    }
    .note-details {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
      max-height: 389px;
      overflow: scroll;
      border-bottom: 0.3px solid #a3a6b3;
    }
  }
  .append-section {
    height: 216px;
    padding: 18px 32px 32px 32px;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      display: block;
    }
    textarea {
      height: 79px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      margin-top: 15px;
      padding: 18px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #a3a6b3;
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: 25px;
      button {
        height: 24px;
        width: 115px;
        margin-left: 12px;
        cursor: pointer;
      }
      button.save {
        background: #417eb9;
        border: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        border-radius: 3px;
      }
      button.cancel {
        background: #ffffff;
        border: 2px solid #417eb9;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #417eb9;
        border-radius: 3px;
      }
    }
  }
}

.new-note-modal {
  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #323335;
    border-bottom: 0.3px solid #a3a6b3;
    padding: 32px;
  }
  .patient-info {
    border-bottom: 0.3px solid #a3a6b3;
    height: 47px;
    padding: 18px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
    }
  }
  .note-section {
    padding: 32px 32px 0 32px;
    input.summary {
      height: 43px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      padding-left: 18px;
    }
    textarea.details {
      min-height: 526px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
      padding: 18px;
      margin-top: 18px;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 10px;
    padding-right: 32px;
    padding-bottom: 32px;
    button {
      height: 24px;
      width: 115px;
      margin-left: 12px;
      cursor: pointer;
    }
    button.save {
      background: #417eb9;
      border: 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      border-radius: 3px;
    }
    button.cancel {
      background: #ffffff;
      border: 2px solid #417eb9;
      box-sizing: border-box;
      border-radius: 3px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #417eb9;
      border-radius: 3px;
    }
  }
}
