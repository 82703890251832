@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import 'assets/css/variables';
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  line-height: 1.15;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-size: 24px;
}

body {
  max-width: $app_static_width;
  min-width: $app_static_width;
  font-family: Roboto, serif;
  font-weight: 400;
  font-size: 0.583333rem;
  letter-spacing: normal;
  line-height: 1rem;
  color: #575757;
  margin: 0;
}
//html reset
ul {
  list-style: none;
}
select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}
nav {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* tables still need cellspacing="0" in the markup */
//table, thead, th, tr, td, {
//  margin: 0;
//  padding: 0;
//  border: 0;
//  outline: 0;
//  font-size: 100%;
//  vertical-align: baseline;
//  background: transparent;
//}
//table {border-collapse: collapse; border-spacing: 0;}
//th {font-weight: bold; vertical-align: bottom;}
//td {font-weight: normal; vertical-align: top;}
//table {font-size: inherit;}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
svg#loading {
  animation: spin 2s linear infinite;
}

li {
  &.active a {
    color: #417eb9;
    cursor: pointer;
    transition: all 0.05s ease-out;
    border-bottom: 2px solid $blue;
  }
}

/* 
xs	'screen and (max-width: 599px)'
sm	'screen and (min-width: 600px) and (max-width: 959px)'
md	'screen and (min-width: 960px) and (max-width: 1279px)'
lg	'screen and (min-width: 1280px) and (max-width: 1919px)'
xl	'screen and (min-width: 1920px) and (max-width: 5000px)' */

.li {
  display: inline-block;
  margin: 0 64px 0 0;
  height: 100%;
  line-height: 1.93rem;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  &:hover {
    color: #417eb9;
    cursor: pointer;
    transition: all 0.05s ease-out;
    border-bottom: 2px solid $blue;
  }
  &.active {
    color: #417eb9;
    background-color: transparent;
    transition: all 0.05s ease-out;
    border-bottom: 2px solid $blue;
  }
  a {
    display: block;
    font-weight: 500;
    font-size: 14px;
    //html reset
    color: #a3a6b3;
    text-decoration: none;
    transition: all 0.35s ease-out;
  }
}

.gm-style-moc {
  z-index: 1 !important;
}