@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';
@import 'src/assets/css/buttons';

.contents {
  .header {
    height: 45px;
    padding: 0 18px 18px 18px;
    .rightItems {
      display: flex;
      float: right;
      .dropdownButton {
        cursor: pointer;
        border: 1px solid #417eb9;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 3px 12px;
        align-items: center;
        color: #417eb9;
        .arrow {
          margin-left: 13px;
        }
      }
      svg {
        margin-left: 18px;
        cursor: pointer;
      }
    }
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
}

.statusAtRisk {
  color: $risk;
}
.statusCritical {
  color: $critical;
}
.statusStable {
  color: $stable;
}

.clearedOn {
  color: $medium_grey;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.modal {
  .modalHeader {
    height: 81px;
    border-bottom: 0.3px solid $medium_grey;
    padding: 32px;
    font-weight: 700;

    span {
      font-size: 18px;
      line-height: 21px;
      color: $dark_grey;
    }
  }

  .modalBody {
    ul {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.3px solid $medium_grey;
      align-items: center;
      height: 45px;
      margin: 0px;
      padding-left: 40px;
      padding-top: 10px;

      li {
        display: inline;
        margin-right: 65px;

        img {
          margin-right: 12px;
          margin-left: -8px;
        }
      }
    }

    .insightContent {
      height: 268px;
      padding: 32px;
      border-bottom: 0.3px solid $medium_grey;

      .snapshotTitle {
        h1 {
          margin: 0px 0px 10px 0px;
          font-size: 24px;
          line-height: 28px;
          color: $dark_grey;
        }

        span {
          font-size: 14px;
          line-height: 110%;
          color: $dark_grey;
        }
      }

      .snapshot {
        margin-top: 16px;

        h3 {
          margin: 0px 0px 1px 0px;
          font-size: 14px;
          line-height: 16px;
          color: $dark_grey;
          text-transform: uppercase;
        }
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 42px 32px 35px 0px;

    button {
      min-width: 115px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }

    .clearBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;
    }

    .closeBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
    }
  }
}

.loadingModal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.greenCheck {
  margin-top: 4px;
  margin-bottom: -5px;
}

.clearInsightsBtn {
  height: 24px;
  width: 160px;
  margin-left: 18px;
}

.deleteIcon {
  cursor: pointer;
  margin-left: 24px;
  width: 12px;
  height: 12px;

  img {
    width: 100%;
    height: 100%;
  }
}
