@import 'src/assets/css/variables';

@mixin tooltipBox {
  position: relative;
  background: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  clip-path: polygon(3% 0, 100% 0%, 100% 100%, 3% 100%, 0% 50%);
  z-index: 9999;
  filter: drop-shadow(0 5px 10px #a3a6b3);
}

.locationHeader {
  width: 100%;
  height: 60px;
  nav {
    background: transparent;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
    .navLeft {
      padding: 0;
      text-align: center;
      li {
        float: left;
        font-family: Roboto, sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        margin-right: 16px;
        height: 24px;
        list-style-type: none;
        cursor: pointer;
      }
    }

    .navRight {
      padding: 0;
      text-align: center;
      display: flex;
      .options {
        margin-left: 18px;
        float: right;
        font-size: 12px;
      }
      .dropdownButton {
        cursor: pointer;
        margin: 0;
        border: 1px solid #417eb9;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 5px 12px;
        align-items: center;
        color: #417eb9;
        .arrow {
          margin-left: 13px;
        }
      }
    }
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
}

.labelCheck {
  width: auto;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: #323335;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 7px;
    margin: 0;
    z-index: 1;
    cursor: pointer;
    width: 23px;
    height: 23px;
    border-radius: 3px;
  }

  span {
    position: relative;
    top: 1px;
    background: $light-grey;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    width: 23px;
    height: 23px;
    border-radius: 3px;
    border: 1px solid $blue;
  }

  input[type='checkbox']:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 12px;
    background: url('../../../assets/icons/check-mark.svg') no-repeat;
    background-size: 100%;
    border-radius: 3px;
  }
}

.mapContainer {
  height: 700px;
  min-height: 500px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  height: inherit;
  flex-direction: column;
}

.box {
  @include tooltipBox;
  width: 360px;
  height: 210px;
  left: 25px;
  top: -122px;
  padding: 30px 27px 30px 41px;
  .title {
    font-size: 17px;
    line-height: 17px;
    align-items: center;
    text-transform: uppercase;
    color: #ed6f6a;
    margin: 0 0 21px;
  }
  p {
    span {
      font-weight: 600;
    }
    color: #323335;
    display: block;
    margin: 0;
    padding-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
  }
}

.smallBox {
  @include tooltipBox;
  width: 200px;
  height: 35px;
  left: 13px;
  top: -30px;
  padding: 10px 10px 10px 15px;
  background-color: #355873;
  p {
    line-height: 17px;
    align-items: center;
    margin: 0;
    color: #FFFFFF;
    font-weight: 400;
    size: 12px
  }
}

.hoverTooltip {
  top: -190px;
  left: -540px;
  padding: 0;
  background: #f5f6fa;
}


.houesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  width: 150px;
}
.hoursList {
  display: flex;
  flex-direction: row;
}

.theBorder {
  display: flex;
  flex-direction: row;
}

.theHours {
  margin-left: 10px;
  font-size: 10px;
  border: 1px solid black;
  padding: 2px 5px;
  border-radius: 5px;
}