.card {
  width: 310px;
  filter: drop-shadow(0px 2px 6px #e5e5e5);
  height: 200px;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 25px;
  .cardTitle {
    color: #323335;
    font-size: 17px;
    margin-bottom: 9px;
    font-weight: 600;
  }
  .cardSubtitle {
    color: #707070;
    font-size: 12px;
    line-height: 13px;
  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 5px 0;
    height: 100px;
    gap: 5px;
    figure {
      margin: 0;
    }
    .statusGroup {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-around;
      span {
        font-size: 12px;
        color: #707070;
        margin-right: 5px;
        text-align: center;
        line-height: 14px;
      }
    }
  }
}
