.moodToolTipReverse {
  background-color: #fff;
  height: 85.5px;
  width: 150px;
  padding: 5px;
  border-top: 1px solid #a3a6b3;
  border-left: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 35px;
  margin-top: -32px;
  z-index: 9999;
}

.moodToolTipReverse:before {
  left: calc(100% - 35px);
  top: 29%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-left-color: #a3a6b3;
  border-width: 42.25px;
  margin-top: -47.25px;
  z-index: 9999;
}

.moodToolTipReverse:after {
  left: calc(100% - 35px);
  top: 29%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-left-color: #fff;
  border-width: 41.25px;
  margin-top: -46.25px;
  z-index: 9999;
}

.moodToolTip {
  background-color: #fff;
  height: 85.5px;
  width: 150px;
  padding: 5px;
  border-top: 1px solid #a3a6b3;
  border-right: 1px solid #a3a6b3;
  border-bottom: 1px solid #a3a6b3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 35px;
  margin-top: -32px;
  z-index: 9999;
}

.moodToolTip:before {
  right: calc(100% - 35px);
  top: 29%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-right-color: #a3a6b3;
  border-width: 42.25px;
  margin-top: -47.25px;
  z-index: 9999;
}

.moodToolTip:after {
  right: calc(100% - 35px);
  top: 29%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 128, 0, 0);
  border-right-color: #fff;
  border-width: 41.25px;
  margin-top: -46.25px;
  z-index: 9999;
}

.moodTooltipContent {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  li {
    font-size: 12px;
    line-height: 14px;
    color: #323335;
    font-weight: normal;
    font-style: normal;
    margin: 3px 0px;
    span {
      font-weight: 600;
    }
  }
}
