.progressContainerScoreLabel {
  margin-bottom: 14px;
  width: 271px;
  height: 14px;
  background: #ffffff;
  box-shadow: 0 2px 6px #e5e5e5;
  display: flex;
  border-radius: 10px;
  z-index: 1;
  .scoreLabelContainer {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
    color: #323335;
    font-size: 11px;
    &.small {
      font-size: 9px;
      line-height: 11px;
    }
  }
}
