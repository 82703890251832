@import 'src/assets/css/variables.scss';

.imageBackground {
  background: url('../../assets/images/login-image.jpg') no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 65%;
  right: 0;
  top: 0;
}
.loginWrapper {
  @media screen and (max-width: 768px) {
    justify-content: center;
    background: #fafafa;
  }

  .login {
    background: #fafafa;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2.5rem;
    height: auto;
    min-height: 100vh;
    width: 35%;
    display: flex;
    @media screen and (max-width: 1280px) {
      padding: 1rem 1.5rem;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      padding: 1rem 10%;
      z-index: 999;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      top: 0;
      right: -2px;
    }

    section {
      .welcome {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #2f2a30;
      }

      .logo {
        margin: unset;

        img {
          width: 100%;
        }
      }
    }

    .loginGroup {
      display: flex;
      flex-direction: column;
      padding: 0rem 0 0;

      .loginInput {
        width: 100%;
        margin-top: 15px;
        //-webkit-appearance: none;
        //-moz-appearance: none;
        //-ms-appearance: none;
        //-o-appearance: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        height: 1rem;
        color: #000;
        display: inline-block;
        border-bottom: 1px solid #999;
        padding: 0 0.25rem;
        max-height: 1rem;
        font: inherit;
        font-size: 0.541667rem;
        touch-action: manipulation;
        overflow: visible;

        &:not([readonly]) {
          background: linear-gradient(to bottom, transparent 95%, #0092d1 95%) no-repeat;
          background-size: 0 100%;
          transition: background-size 0.2s ease;

          &:focus {
            border-bottom: 1px solid #0092d1;
            background-size: 100% 100%;
            outline: 0;
          }
        }
      }

      .error {
        padding: 0.375rem 0.5rem;
        background: #c72100;
        border-radius: 0.125rem;
        line-height: 0.75rem;

        &:before {
          content: '';
          background: url(data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20viewBox%3D%225%205%2026%2026%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Cstyle%3E.clr-i-outline%7Bfill%3A%23fafafa%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Ctitle%3Eexclamation-circle-line%3C%2Ftitle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-1%22%20d%3D%22M18%2C6A12%2C12%2C0%2C1%2C0%2C30%2C18%2C12%2C12%2C0%2C0%2C0%2C18%2C6Zm0%2C22A10%2C10%2C0%2C1%2C1%2C28%2C18%2C10%2C10%2C0%2C0%2C1%2C18%2C28Z%22%3E%3C%2Fpath%3E%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-2%22%20d%3D%22M18%2C20.07a1.3%2C1.3%2C0%2C0%2C1-1.3-1.3v-6a1.3%2C1.3%2C0%2C1%2C1%2C2.6%2C0v6A1.3%2C1.3%2C0%2C0%2C1%2C18%2C20.07Z%22%3E%3C%2Fpath%3E%3Ccircle%20class%3D%22clr-i-outline%20clr-i-outline-path-3%22%20cx%3D%2217.95%22%20cy%3D%2223.02%22%20r%3D%221.5%22%3E%3C%2Fcircle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E);
          margin: 0.0416666667rem 0.25rem 0 0;
          height: 0.666667rem;
          width: 0.666667rem;
          flex: 0 0 0.666667rem;
        }

        text-align: center;
        color: white;
        margin-bottom: 25px;
      }

      .emailSent {
        margin-top: 20px;
        text-align: center;
      }

      .loginButton {
        margin-top: 30px;
        min-width: 100%;
        background-color: $blue;
        color: $white;
        border: none $blue;

        //* updates after removing library
        border-radius: 3px;
        outline: none;
        font-family: Roboto, serif;
        font-style: normal;
        font-weight: normal;
        text-transform: uppercase;
        cursor: pointer;
        letter-spacing: 0.12em;
        font-size: 0.5rem;
        height: 1.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;

        &:hover {
          background-color: #004c8a;
          color: #e0f0f6;
        }

        &:disabled {
          color: #575757;
          cursor: not-allowed;
          opacity: 0.4;
          background-color: #ccc;
          border-color: #ccc;
          box-shadow: none;
        }
      }

      .belowButton {
        margin-top: 20px;
        cursor: pointer;
        font-size: 0.5833rem;
        text-align: center;

        &:hover {
          color: $blue;
          text-decoration: underline;
        }

        &:active {
          color: $purple;
        }
      }
    }
  }
}
