@import 'src/assets/css/variables';
@import 'src/assets/css/buttons';

.modal {
  height: 426px;
  max-height: 426px;
  position: relative;

  .modalHeader {
    height: 81px;
    border-bottom: 0.3px solid $medium_grey;
    padding: 32px;
    font-weight: 700;

    span {
      font-size: 18px;
      line-height: 21px;
      color: $dark_grey;
    }
  }

  .modalBody {
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 18px;

    p {
      font-weight: 700;
    }

    span {
      color: $medium_grey;
      margin-top: 4px;
    }
  }

  .modalFooter {
    position: absolute;
    bottom: 32px;
    right: 32px;

    .btnMargin {
      margin-left: 18px;
    }
  }
}
