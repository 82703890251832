@import '../../../../assets/css/variables';

.form {
  min-height: 481px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  .body {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.modalHeader {
  border-bottom: 0.3px solid $medium_grey;

  h4 {
    margin: 0 auto;
    text-transform: uppercase;
    color: $dark_grey;
    font-size: 18px;
    line-height: 12px;
    padding: 30px 32px;
    font-weight: bold;
  }
}

.checkBoxRow {
  margin-top: 45px;
  &.remoteCheckbox {
    width: calc(100% - 34%);
  }
}

.bottomButtons {
  justify-content: space-between;
  margin: 0 32px 0 32px;
  button {
    min-width: 115px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    border-radius: 3px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }

  .submitBtn {
    background: $blue;
    border: none;
    color: $white;
    margin-left: 12px;

    &:hover {
      background-color: #004c8a;
      color: #e0f0f6;
    }
  }

  .closeBtn {
    margin-left: auto;
    background: $white;
    border: 2px solid $blue;
    color: $blue;
  }

  .rightButtons {
    display: flex;
    margin-bottom: 5px;

    .closeBtn {
      margin-right: 5px;
    }
  }
}

.repeatSection {
  display: flex;
  min-width: 500px;
  div {
    margin-right: 27px;
  }
}
.bottomSection {
  display: flex;

  div {
    margin-bottom: 0;
  }
  .rightSection {
    display: flex;
    flex-direction: column;
  }
}

.repeatEndingSection {
  width: 337px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  position: relative;

  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }

  input {
    height: 30px;
    min-height: 30px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }
  .repeatCheckboxes {
    display: flex;
    margin-top: 5px;
    margin-right: 21px;
    &.afterCheckbox {
      margin-right: 27px;
    }
  }
  .repeatInputs {
    display: flex;
    flex-flow: row wrap;
  }
  .labelOccurrences {
    margin-left: 10px;
  }
}

.applyChangesSection {
  display: flex;
  flex-direction: column;
  .label {
    font-size: 14px;
    line-height: 110%;
    font-weight: 600;
  }

  .radioButtons {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 25px;
    }
  }
}

.errorText {
  color: $critical;
  font-size: 12px;
  line-height: 110%;

  &__checkbox {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
    opacity: 0;
  }

  &__checkbox__show {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
  }
}

.loadingModal {
  height: 481px;
  display: flex;
  align-items: center;
}

.input {
  height: 40px;
  min-height: 40px;
  background: #f5f6fa;
  border: 0;
  border-radius: 3px;
  padding-left: 10px;
  width: 337px;
  &:focus {
    outline: none;
  }
}

.option {
  font-weight: normal;
  font-size: 14px;
  background: $light_grey;
  color: #323335;
  width: 337px;
  height: 60px;
  padding: 10px;
  margin-top: -10px;
  &:hover {
    background: $blue;
    color: $white;
  }
  &:focus {
    background: $blue;
    color: $white;
  }
}
