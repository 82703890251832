@import 'src/assets/css/variables';

.removeModal {
  height: 481px;
  position: relative;

  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 32px;
  }

  .body {
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .userInfo {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      span {
        font-size: 18px;
        line-height: 21px;
      }

      .picturePlaceholder {
        width: 24px;
        height: 24px;
        min-height: 24px;
        min-width: 24px;
        border-radius: 50%;
        background-color: $light_grey;
        margin-right: 5px;

        &__loaded {
          width: 24px;
          height: 24px;
          max-height: 24px;
          max-width: 24px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 5px;
        }
      }
    }

    .role {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 32px;
    }

    .confirmationText {
      font-size: 16px;
      line-height: 110%;
      font-weight: 700;
      margin-bottom: 18px;
      text-align: center;
      max-width: 770px;
    }

    .footNote {
      font-size: 16px;
      line-height: 110%;
      color: $medium_grey;
    }
  }

  .buttons {
    float: right;
    position: absolute;
    bottom: 32px;
    right: 32px;

    button {
      text-decoration: none;
      outline: none;
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      height: 24px;
      min-height: 24px;
    }

    .closeBtn {
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      width: 115px;
      min-width: 115px;
    }

    .submitBtn {
      background: $blue;
      border: none;
      color: $white;
      margin-left: 12px;
      width: 132px;
      min-width: 132px;
      &:disabled {
        background: $medium_grey;
        cursor: not-allowed;
      }
    }
  }
}

.loadingState {
  padding: 120px 0px;
}
