@import 'src/assets/css/variables';

.relapse {
  background: #ffffff;
  min-width: 264px;
  width: 264px;
  height: 271px;
  box-shadow: 0px 2px 6px #e5e5e5;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  position: relative;

  .header {
    display: flex;
    align-items: center;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      text-transform: uppercase;
      margin-right: 6px;
    }
  }

  .infoTooltip {
    width: 509px;
    font-size: 12px;
    line-height: 14px;
    background: $light_grey;
    color: #444444;
    padding: 20px 15px 15px 35px;
    right: 53px;
    top: -120px;
    left: unset;

    .mainTitle {
      color: $dark_grey;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      margin: 0 0 15px;
    }

    .subtitle {
      font-size: 11px;
      font-style: italic;
    }

    ul {
      margin: 0 0 18px;

      li {
        margin: 0;
      }
    }

    p {
      margin: 0 0 12px;
    }

    .smallText {
      padding-left: 7px;
      line-height: 11px;
      font-size: 11px;
      margin-top: 3px;
    }

    .bottomContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .relapseRange {
      p {
        display: flex;
        margin: 0 0 6px;

        .range {
          display: block;
          width: 40px;
          text-align: start;
          font-weight: 700;
        }

        .text {
          margin-left: 7px;
        }
      }
    }

    .statusContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .statusLabel {
        &.critical {
          background: linear-gradient(90deg, rgb(209, 129, 149) 0%, rgb(234, 105, 105) 100%);
        }

        &.atRisk {
          background: linear-gradient(90deg, rgb(227, 146, 83) 0%, rgb(240, 191, 85) 100%);
        }

        &.stable {
          background: linear-gradient(270deg, rgb(107, 227, 186) 0%, rgb(92, 197, 226) 70%);
        }

        text-align: center;
        width: 100px;
        margin: 0 0 5px;
        line-height: 16px;

        strong {
          display: inline-block;
          font-size: 12px;
          color: rgb(68, 68, 68);
        }
      }
    }

    .inlineText {
      width: 130px;
      margin-top: 25px;
      text-align: left;
      line-height: 12px;
      font-size: 11px;
    }
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #323335;
    margin-top: 27px;
  }

  .note {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #323335;
  }

  .gauge {
    margin-top: 46px;
  }

  .percentage {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    margin-left: -18px;
    bottom: -22px;
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    color: $dark_grey;
    cursor: pointer;
  }

  .relapseScoreHover {
    width: 230px;
    padding: 10px;
    z-index: 999;
    background: $light_grey;
    color: #444444;
    position: absolute;
    box-shadow: 0px 2px 6px #e5e5e5;
    top: 12%;
    left: -100%;
    filter: drop-shadow(0 5px 10px #a3a6b3);

    .relapseContent {
      display: flex;
      padding: 5px;
      align-items: flex-start;
      .textVertical {
        width: 48px;
        height: 130px;
        background: linear-gradient(0deg, #f5ebee 0%, #f13131 100%);
        position: relative;
        display: inline-block;

        .rotate {
          position: absolute;
          top: 50%;
          left: 50%;
          background: linear-gradient(90deg, #f5ebee 0%, #f33131 100%);
          transform: translateX(-50%) translateY(-46%) rotate(270deg);
          display: flex;
          flex-direction: row-reverse;
          span {
            transform: translateX(16%) translateY(32%) rotate(90deg);
            img {
              width: 58px;
              height: 40px;
            }
          }
        }
      }
      .componentsContainer {
        margin-left: 10px;
        div {
          text-transform: capitalize;
        }
      }
    }
    .hoverRight {
      position: absolute;
      bottom: 0;
      right: -30px;
      width: 30px;
      height: 100%;
      background: $light_grey;
      -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
      clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
    }
  }
}
