@import '../common/form/form.module.scss';

.main {
  width: 730px;
  display: flex;
  flex-direction: column;
  margin: 32px;
  position: relative;
}

.row {
  display: flex;
  justify-content: space-between;
}

.notificationsConfirm {
  display: flex;
  flex-direction: column;

  .checkboxTitle {
    margin-bottom: 12px;
    font-size: 14px;
    position: relative;
    .tooltipIcon {
      margin-left: 4px;
    }
  }
}

.notificationsTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 15.5px;
}

ul {
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 10px;
  }
}

.timeSettings {
  position: relative;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 15.5px;
    margin-bottom: 18px;
  }
  span {
    margin: 0px 12px;
  }
  label {
    margin-top: 25px;
    font-weight: 600;
  }
}

.hourInput {
  width: 126px;
  height: 40px;
  background: $light_grey;
  border-radius: 3px;
  border: 0;
  padding-left: 10px;
  margin-right: 8px;

  &:focus {
    outline: none;
  }

  &__warning {
    width: 126px;
    height: 40px;
    margin-right: 8px;
    background: $light_grey;
    border-radius: 3px;
    border: 0;
    padding-left: 10px;
    border: 2px solid $critical;

    &:focus {
      outline: none;
    }
  }
}

.time {
  width: 126px;
  padding-left: 10px;
  height: 40px;
  border-radius: 3px;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: $light_grey;
  background-image: url('../../assets/icons/arrow-down.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;

  &:focus {
    outline: none;
  }
}

.formBlock {
  position: absolute;
  width: 900px;
  height: 500px;
  left: -10px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  opacity: 1;
  cursor: not-allowed;
  &.formInputs {
    width: 650px;
    height: 400px;
    top: 100px;
  }
}

.helperAnchor {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0px;
  right: 175px;
  padding-left: 4px;
  cursor: pointer;

  .helper {
    height: 10px;
    width: 10px;
    border: 0.5px solid $blue;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;

    img {
      width: 4px;
      height: 8px;
    }
  }
}

.tooltip {
  width: 360px;
  height: 100px;
  font-size: 12px;
  line-height: 14px;
  top: -40px;

  p {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
  }
}

.errorText {
  color: $critical;
  font-size: 12px;
  line-height: 110%;

  &__checkbox {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
    opacity: 0;
  }

  &__checkbox__show {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    display: block;
    margin-top: 8px;
  }
}

.datepickerError {
  position: absolute;
  top: 120px;
  left: -10px;
  color: $critical;
  font-size: 12px;
}
