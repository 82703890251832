@import 'src/assets/css/variables';

.navHeader {
  padding: 18px 0;
  height: 60px;
  position: relative;
  span {
    display: inline-block;
    height: 24px;
    border-radius: 3px;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 10px;
    margin-right: 14px;
    cursor: pointer;
    text-align: center;
    &.active {
      background: $medium_grey;
      color: $white;
    }
    &.inactive {
      color: $medium_grey;
      &:hover {
        background: $medium_grey;
        color: $white;
      }
    }
  }
}
