@import '../../../../assets/css/variables';

.facialMatchModal {
  margin: 0 auto;

  .modalHeader {
    border-bottom: 0.3px solid #a3a6b3;

    h4 {
      margin: 0 auto;
      text-transform: uppercase;
      color: $dark_grey;
      font-size: 18px;
      line-height: 12px;
      padding: 30px 32px;
      font-weight: bold;
    }
  }

  .facialMatchDetail {
    height: 45px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border-bottom: 0.3px solid $medium_grey;

    .box {
      font-family: Roboto, serif;
      font-size: 14px;
      line-height: 9px;
      align-items: center;
      color: $dark_grey;
      padding: 18px 18px;
      float: left;
      width: 29%;

      span {
        font-weight: bold;
      }
    }

    .box:first-child {
      padding-left: 32px;
    }

    .box:last-child {
      text-align: end;
      width: 13%;
      padding-right: 34px;
    }
  }

  .facialMatchContainer {
    height: 700px;
    min-height: 400px;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      text-align: center;
      padding: 32px 32px;
      margin: 0;
      color: #323335;
    }

    .images {
      display: flex;
      align-items: center;
      justify-content: center;

      .imageContent {
        margin: 0 12px;
        width: 331px;

        img {
          width: 100%;
          height: 374px;
          border-radius: 5px;
          object-fit: cover;
        }

        .text {
          font-family: Roboto, serif;
          font-size: 14px;
          color: #323335;
          display: block;
        }
      }

      .noImage {
        background-size: cover;
        width: 331px;
        height: 374px;
        border-radius: 5px;
        background: url('../../../../assets/images/no-image.svg') no-repeat bottom $light_grey;
        margin-bottom: 5px;
      }
    }

    .options {
      margin: 60px 0 30px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        background: #ffffff;
        box-shadow: 0px 2px 6px #e5e5e5;
        border-radius: 3px;
        padding: 0;
        width: 299px;
        height: 60px;
        margin-right: 19px;
        font-weight: 600;
        font-size: 24px;
        color: #323335;
        font-family: Roboto, serif;
        border: none;
        cursor: pointer;
        //padding-right: 37px;
        &:focus {
          outline: none;
        }

        i {
          position: relative;
          top: 4px;
          right: 18px;
          display: inline-block;
          width: 24px;
          height: 24px;
          background: $light_grey;
          border-radius: 100%;
        }
      }
      .yesButton {
        padding-right: 40px;
      }
      .selected {
        background: rgba(162, 166, 179, 0.2);
        border: 1px solid rgba(50, 51, 53, 0.2);
        i {
          background: url('../../../../assets/icons/check_icon.svg') no-repeat center center;
        }
      }
    }

    .rowButtons {
      float: right;
      margin: 30px 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        padding: 5px 25px;
        margin-right: 18px;
        min-width: 115px;
        height: 24px;
        border: none;
        font-family: Roboto, serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        background: $blue;
        border-radius: 3px;
        color: $white;
        cursor: pointer;
      }

      .saveButton {
        display: flex;
        align-items: center;
        text-align: center;
        margin-right: 34px;

        &:hover {
          background-color: #004c8a;
          color: #e0f0f6;
        }
      }

      .cancelButton {
        color: $blue;
        border: 2px solid $blue;
        background: $white;
      }
    }
  }
}
