@import 'src/assets/css/variables';

@mixin verticalLine {
  position: absolute;
  top: 60px;
  border: 1px solid #D8D8D8;
  height: calc(100% - 90px);
  margin-top: 35px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff;
  width: 100%;
  margin-bottom: 30px;
  padding: 32px 32px 24px 170px;
  .title {
    font-size: 18px;
    color: #323335;
    font-weight: 700;
    line-height: 21px;
    width: 100%;
  }
  .tableContainer{
    position: relative;
    .tableHeader {
      display: flex;
      width: 100%;
      gap: 10px;
      height: 40px;
      > div {
        width: 100px;
        font-size: 16px;
        line-height: 14px;
        font-weight: bold;
        color: #323335;
        height: 100%;
        padding: 15px 0px;
        display: flex;
        align-items: center;
      }
      div:first-child {
        width: 200px;
      }
    }
    .verticalLine {
      @include verticalLine;
      left: 305px;
    }
    .verticalLineRight {
      @include verticalLine;
      left: 520px;
    }
  }

  .chartContainer {

  }

}
.complianceTabs {
  //html reset
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
  height: 100%;
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease-out;
}
.li {
  display: inline-block;
  margin: 0 64px 0 0;
  height: 100%;
  line-height: 1.93rem;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  &:hover {
    color: #417eb9;
    cursor: pointer;
    transition: all 0.05s ease-out;
    border-bottom: 2px solid $blue;
  }
  &.active {
    color: #417eb9;
    background-color: transparent;
    transition: all 0.05s ease-out;
    border-bottom: 2px solid $blue;
  }
  a {
    display: block;
    font-weight: 500;
    font-size: 14px;
    //html reset
    color: #a3a6b3;
    text-decoration: none;
    transition: all 0.35s ease-out;
  }
}
.complianceNav {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 100%;
  float: left;
  border-bottom: 1px solid #a3a6b3;
  height: 49px;
  margin-top: 8px;
  .download-button {
    cursor: pointer;
  }
}

// .form {
//   min-height: 481px;
//   margin-bottom: 32px;
//   display: flex;
//   flex-direction: column;
//   .body {
//     margin: 32px;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//   }
// }
.saveBtn {
  background: $blue;
  border: none;
  color: $white;
  margin-left: 12px;
}

.closeBtn {
  background: $white;
  border: 2px solid $blue;
  color: $blue;
}
// button {
//   min-width: 115px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-decoration: none;
//   outline: none;
//   border-radius: 3px;
//   font-size: 12px;
//   line-height: 14px;
//   cursor: pointer;
// }