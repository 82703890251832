@import 'src/assets/css/variables';
@import 'src/assets/css/table-base';

.bacHeader {
  width: 100%;
  height: 60px;
  nav {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    width: 100%;
    float: left;
    height: 60px;
    .navLeft {
      padding: 0;
      text-align: center;
      li {
        float: left;
        font-family: Roboto, sans-serif;
        font-weight: normal;
        font-size: 12px;
        padding: 5px 12px;
        line-height: 15px;
        margin-right: 8px;
        height: 24px;
        list-style-type: none;
        cursor: pointer;
        &.active {
          background: #a3a6b3;
          border-radius: 3px;
          color: #fff;
        }
        &.inactive {
          color: #a3a6b3;
          &:hover {
            background: #a3a6b3;
            border-radius: 3px;
            color: #fff;
          }
        }
      }
    }
  }

  table,
  thead,
  th,
  tr,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  th {
    font-weight: bold;
    vertical-align: bottom;
  }
  td {
    font-weight: normal;
    vertical-align: top;
  }
  table {
    font-size: inherit;
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
}

.status {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.surveyHeader {
  .surveyNav {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    width: 100%;
    border-bottom: 1px solid #a3a6b3;
    display: flex;
    flex-direction: column;
    height: auto !important;
    .surveyTabs {
      //html reset
      display: flex;
      position: relative;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
      list-style: none;
      height: 100%;
      width: 100%;
      transition: all 0.35s ease-out;
      -webkit-transition: all 0.35s ease-out;
      li {
        display: inline-block;
        margin: 0 64px 0 0;
        height: 100%;
        line-height: 1.93rem;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
        a {
          display: block;
          font-weight: 500;
          font-size: 14px;
          //html reset
          color: #a3a6b3;
          text-decoration: none;
          transition: all 0.35s ease-out;
        }
        &:hover a {
          color: #417eb9;
          cursor: pointer;
          transition: all 0.05s ease-out;
          border-bottom: 2px solid $blue;
        }
      }
      li.active a {
        color: #417eb9;
        background-color: transparent;
        transition: all 0.05s ease-out;
        border-bottom: 2px solid $blue;
      }
    }

    .downloadButton {
      cursor: pointer;
      justify-self: flex-end;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  .navRight {
    padding: 0;
    text-align: center;
    display: flex;
    width: 100%;
    li {
      &:not(:first-child) {
        margin-left: 18px;
      }
      padding: 0;
      height: 24px;
      font-size: 12px;
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: normal;
      cursor: pointer;
      a {
        padding: 5px 15px;
        min-width: 150px;
        text-decoration: none;
        border: none;
        outline: none;
        background: #417eb9;
        border-radius: 3px;
        color: $white;
        cursor: pointer;
        &:hover {
          background-color: #004c8a;
          color: #e0f0f6;
        }
      }
    }

    .dropdownButton {
      cursor: pointer;
      margin: 0;
      border: 1px solid #417eb9;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 5px 12px;
      align-items: center;
      color: #417eb9;
      .arrow {
        margin-left: 13px;
      }
    }
  }
}

@media (max-width: 1440px) {
  header.compliance-header nav.compliance-nav ul.compliance-tabs li {
    margin-right: 30px;
  }
}
.headerOptions {
  background: transparent;
  margin-top: 29px;
  margin-bottom: 22px;
}
.viewButton {
  color: #417eb9;
  background: transparent;
  border: 0;
  outline: 0;
  border-radius: 0;
}
.noData {
  font-size: 12px;
  color: #a3a6b3;
}
