div.toast {
  width: 360px;
  min-height: 56px;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px #e5e5e5;
  border-radius: 3px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  z-index: 9999;
  .icon-container {
    width: 30px;
    text-align: center;
    padding: 8px 0px;
    background-color: #ed6f6a;
    &.success {
      background-color: #6fcf97;
    }
    &.warning {
      background-color: #efbb55;
    }
    &.error {
      background-color: #ed6f6a;
    }
  }
  .text-container {
    width: 292px;
    padding: 8px 12px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    color: #323335;
    font-style: normal;
  }
  .close-container {
    padding: 8px 12px;

    svg {
      color: rgb(80, 95, 121);
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
