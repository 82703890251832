.card {
  background: #ffffff;
  width: 410px;
  min-width: 410px;
  height: 271px;
  box-shadow: 0px 2px 6px #e5e5e5;
  padding-top: 18px;
  margin-right: 18px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  .top {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      text-transform: uppercase;
      margin-right: 6px;
    }
  }

  .middle {
    .note {
      border-bottom: 0.3px solid #a3a6b3;
      width: 100%;
      height: 158px;
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 30px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #323335;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .summary {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #323335;
        display: block;
        margin-top: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-line;
      }
      .readMore {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #417eb9;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 18px;
        display: block;
      }
    }
    .noNote {
      padding-left: 18px;
      padding-right: 18px;
      height: 158px;
      padding-top: 30px;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #a3a6b3;
        display: block;
      }
      .details {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: block;
        color: #a3a6b3;
        margin-top: 9px;
      }
    }
  }

  .bottom {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    height: 72px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
    align-items: flex-end;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 8px;
      span {
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        color: #323335;

        &.label {
          font-weight: bolder;
          display: block;
        }
        &.value {
          font-weight: 100;
        }
      }
    }

    .right {
      button {
        background: #417eb9;
        border-radius: 3px;
        width: 115px;
        height: 24px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: white;
        border: 0;
        cursor: pointer;
      }
    }
  }

  .infoTooltip {
    width: 400px;
    height: 60px;
    font-size: 12px;
    line-height: 14px;
    padding: unset;
    .hoverText {
      background: #f5f6fa;
      width: inherit;
      height: inherit;
      padding: 24px 22px;
      color: #444444;
    }
  }
}
