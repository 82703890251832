@import 'src/assets/css/variables';

.container {
  background-color: #f5f6fa;
  min-height: 100vh;
  width: 100%;

  .menuRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    padding-top: 33px;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .search-box {
        border: 1px solid #417eb9;
        box-sizing: border-box;
        border-radius: 3px;
        width: 278px;
        height: 24px;
        padding-left: 27.75px;
        padding-right: 9.75px;
        display: flex;
        align-items: center;
        background: none;
        background-image: url('../../assets/icons/icn_search.svg');
        background-repeat: no-repeat, repeat;
        background-position: left 0.7em top 50%, 0 0;

        .clear-icon {
          cursor: pointer;
        }

        input {
          margin-left: 4px;
          border: 0;
          background-color: #f5f6fa;
          height: 100%;
          width: 90%;
        }
        input::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #a3a6b3;
        }

        input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #a3a6b3;
        }

        input::-ms-input-placeholder {
          /* Microsoft Edge */
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #a3a6b3;
        }
        input:focus {
          outline: none;
        }

        div {
          width: 10px;
          height: 10px;
          position: relative;
          bottom: 7px;
          left: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      select {
        width: 115px;
        height: 24px;
        margin-right: 32px;
        border: 1px solid #417eb9;
        border-radius: 3px;
        box-sizing: border-box;
        background-image: none;
        color: #417eb9;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 18px;

      .personalView {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #a3a6b3;
      }
      svg {
        margin-left: 8px;
      }
      .changedText {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        margin-left: 3px;
        /* Dark Grey */
        color: #a3a6b3;
        display: flex;
        align-items: center;

        span {
          margin-left: 4px;
          margin-right: 4px;
          border-bottom: 1px solid #417eb9;
          line-height: 14px;
          color: #417eb9;
          cursor: pointer;
        }
      }

      .lockIcon {
        margin-right: 3px;
      }
    }
  }

  .rowHeader {
    background-color: #ffffff;
    width: calc(100% - 36px);
    height: 41px;
    box-shadow: 0px 2px 6px #e5e5e5;
    padding: 12px 18px;
    margin: 31px 18px 0px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-weight: bold;
        font-size: 24px;
        color: #444444;
        line-height: 28px;
      }
      .count {
        margin-left: 6px;
        font-size: 18px;
        line-height: 21px;
        color: #444444;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .title {
        font-size: 12px;
        line-height: 14px;
        margin-right: 10px;
      }
    }
  }

  .cardRow {
    width: calc(100% - 36px);
    margin-right: 18px;
    margin-left: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    .loadingCard {
      height: 320px;
      width: 267px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .tableContainer {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 33px;
  }

  .noClients {
    height: 535px;
    width: calc(100% - 36px);
    background: #ffffff;
    box-shadow: 0px 2px 6px #e5e5e5;
    border-radius: 3px;
    margin: 31px 18px 0px 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
      margin-top: 131px;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #323335;
      margin-top: 40px;
    }
    .subTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #323335;
      margin-top: 18px;
    }
    .addButton {
      margin-top: 64px;
      background: #417eb9;
      border-radius: 3px;
      width: 147px;
      height: 48px;
      border-width: 0px;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}

.loadingContainer {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
}

.full-view {
  position: absolute;
}

.viewSettings {
  display: flex;
  align-items: center;
}

.cardView {
  margin-right: 18px;
  margin-left: 32px !important;
  cursor: pointer;
  color: $medium_grey;
}

.link {
  height: 24px;
}

.pointer {
  cursor: pointer;
}
