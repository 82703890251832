@import 'src/assets/css/variables';
@import 'src/assets/css/buttons';

.field {
  width: 337px;
  height: 78px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  position: relative;

  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }

  input {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  .inputError {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $critical;

    &__textColor {
      height: 40px;
      min-height: 40px;
      background: $light_grey;
      border: 0;
      border-radius: 3px;
      padding-left: 10px;
      border: 1px solid $critical;
      color: $critical;
    }
  }

  .inputSuccess {
    background: $light_grey;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $stable;
    color: $stable;
    background-image: url('../../../assets/icons/green_check.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
  }

  .inputDisabled {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    color: $medium_grey;
    font-style: italic;
    cursor: not-allowed;
  }

  .error {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    margin-top: 8px;
  }

  select {
    padding-left: 10px;
    height: 40px;
    background: $light_grey;
    border-radius: 3px;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-image: url('../../../assets/icons/arrow-down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;

    &:focus {
      outline: none;
    }
    option {
      font-weight: normal;
      font-size: 14px;
      background: $light_grey;
      color: $dark_grey;
      &:hover {
        background: $blue;
        color: $white;
      }
      &:focus {
        background: $blue;
        color: $white;
      }
    }
  }

  .arrowIcon {
    position: absolute;
    top: 54px;
    right: 18px;
  }
}

.field__small {
  width: 155px;
  height: 78px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  position: relative;

  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }

  input {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  .inputError {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $critical;

    &__textColor {
      height: 40px;
      min-height: 40px;
      background: $light_grey;
      border: 0;
      border-radius: 3px;
      padding-left: 10px;
      border: 1px solid $critical;
      color: $critical;
    }
  }

  .inputSuccess {
    background: $light_grey;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $stable;
    color: $stable;
    background-image: url('../../../assets/icons/green_check.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
  }

  .inputDisabled {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    color: $medium_grey;
    font-style: italic;
    cursor: not-allowed;
  }

  .error {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    margin-top: 8px;
  }
}

.field__smallNoLabel {
  width: auto;
  height: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  position: relative;

  label {
    margin-bottom: 12px;
  }

  input {
    height: 30px;
    min-height: 30px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  .inputError {
    height: 30px;
    min-height: 30px;
    background: $light_grey;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $critical;

    &__textColor {
      height: 30px;
      min-height: 30px;
      background: $light_grey;
      border-radius: 3px;
      padding-left: 10px;
      border: 1px solid $critical;
      color: $critical;
    }
  }

  .inputSuccess {
    background: $light_grey;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $stable;
    color: $stable;
    background-image: url('../../../assets/icons/green_check.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
  }

  .inputDisabled {
    height: 30px;
    min-height: 30px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    color: $medium_grey;
    cursor: not-allowed;
  }

  .error {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    margin-top: 8px;
  }
}

.field__big {
  width: 518px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  position: relative;

  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }

  input {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
    &:disabled {
      color: $medium_grey;
      font-style: italic;
    }
  }

  .inputError {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $critical;

    &__textColor {
      height: 40px;
      min-height: 40px;
      background: $light_grey;
      border: 0;
      border-radius: 3px;
      padding-left: 10px;
      border: 1px solid $critical;
      color: $critical;
    }
  }

  .inputSuccess {
    background: $light_grey;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid $stable;
    color: $stable;
    background-image: url('../../../assets/icons/green_check.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
  }

  .inputDisabled {
    height: 40px;
    min-height: 40px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    color: $medium_grey;
    font-style: italic;
    cursor: not-allowed;
  }

  .error {
    color: $critical;
    font-size: 12px;
    line-height: 110%;
    margin-top: 8px;
  }

  select {
    padding-left: 10px;
    height: 40px;
    background: $light_grey;
    border-radius: 3px;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-image: url('../../../assets/icons/arrow-down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;

    &:focus {
      outline: none;
    }
    option {
      font-weight: normal;
      font-size: 14px;
      background: $light_grey;
      color: $dark_grey;
      &:hover {
        background: $blue;
        color: $white;
      }
      &:focus {
        background: $blue;
        color: $white;
      }
    }
  }

  textarea {
    height: 229px;
    min-height: 146px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;
    resize: none;
    font-family: Roboto, serif;
    &:focus {
      outline: none;
    }
  }

  .arrowIcon {
    position: absolute;
    top: 54px;
    right: 18px;
  }
}

.picturePlaceholder {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: $light_grey;
  margin-right: 5px;

  &__big {
    width: 41px;
    height: 41px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background-color: $light_grey;
    margin-right: 17px;
  }

  &__loaded {
    width: 41px;
    height: 41px;
    max-height: 41px;
    max-width: 41px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 17px;
  }
}

.uploadFileAnchor {
  display: none;
}

.uploadFileLink {
  font-size: 14px;
  line-height: 110%;
  color: $blue;
  cursor: pointer;
}

.uploadFilePlaceholder {
  display: flex;
  align-items: center;
}

.labelCheck {
  width: auto;
  position: relative;
  cursor: pointer;
  color: $dark_grey;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: -5px;
    margin: 0;
    z-index: 1;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 3px;
  }

  span {
    position: relative;
    top: -2px;
    background: #e1e1e3;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    width: 23px;
    height: 23px;
    border-radius: 3px;
  }

  input[type='checkbox']:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 12px;
    background: url('../../../assets/icons/check-mark.svg') no-repeat;
    background-size: 100%;
    border-radius: 3px;
  }
}

.labelCheckError {
  color: $critical;
  span {
    border: 1px solid $critical;
  }
}

.labelRadio {
  width: auto;
  position: relative;
  cursor: pointer;
  color: $dark_grey;

  input[type='radio'] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 7px;
    margin: 0;
    z-index: 1;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }

  span {
    position: relative;
    top: -2px;
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 50%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }

  input[type='radio']:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: url('../../../assets/icons/radio-button-mark.svg') no-repeat;
    background-size: 100%;
  }
}

.filterDatepicker {
  position: absolute;
  top: 2px;
  right: 0;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
  .rdrDefinedRangesWrapper div {
    background: #2f2a30 !important;
  }
}

.dateContainer {
  position: relative;
  .dateIcon {
    position: relative;
    display: inline-grid;
  }

  .dateIcon:after {
    content: '';
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url('../../../assets/icons/icn-calendar-default.svg') center no-repeat;
  }
  .smallDateField {
    height: 30px;
    min-height: 30px;
    width: 185px;
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    position: relative;

    .inputError {
      background: $light_grey;
      border-radius: 3px;
      padding-left: 10px;
      border: 1px solid $critical;

      &__textColor {
        background: $light_grey;
        border-radius: 3px;
        padding-left: 10px;
        border: 1px solid $critical;
        color: $critical;
      }
    }

    .inputSuccess {
      background: $light_grey;
      border-radius: 3px;
      padding-left: 10px;
      border: 1px solid $stable;
      color: $stable;
      background-image: url('../../../assets/icons/green_check.svg');
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
    }

    .inputDisabled {
      background: $light_grey;
      border: 0;
      border-radius: 3px;
      padding-left: 10px;
      color: $medium_grey;
      font-style: italic;
      cursor: not-allowed;
    }

    .error {
      color: $critical;
      font-size: 12px;
      line-height: 110%;
      margin-top: 8px;
    }
  }
  .calendarContainer {
    position: absolute;
    height: 410px;
    max-width: 337px;
    top: 77px;
    z-index: 999;
    background: $white;
    box-shadow: 0px 2px 6px $box-shadow-default;
    border-radius: 3px;

    &__noTime {
      position: absolute;
      top: 77px;
      z-index: 999;
      background: $white;
      box-shadow: 0px 2px 6px $box-shadow-default;
      border-radius: 3px;
    }
    &__noTimeCalendarTop {
      position: absolute;
      bottom: 44px;
      z-index: 999;
      background: $white;
      box-shadow: 0px 2px 6px $box-shadow-default;
      border-radius: 3px;
    }

    .time {
      padding-left: 10px;
      height: 40px;
      background: $light_grey;
      border-radius: 3px;
      -o-appearance: none;
      -ms-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-image: url('../../../assets/icons/arrow-down.svg');
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
    }
  }

  .hourInput {
    width: 126px;
    height: 40px;
    margin: -8px 8px 16px 24px;
    background: $light_grey;
    border-radius: 3px;
    border: 0;
    padding-left: 10px;

    &:focus {
      outline: none;
    }

    &__warning {
      width: 126px;
      height: 40px;
      margin: -8px 8px 16px 24px;
      background: $light_grey;
      border-radius: 3px;
      border: 0;
      padding-left: 10px;
      border: 2px solid $critical;

      &:focus {
        outline: none;
      }
    }
  }

  .arrowIconCalendar {
    position: relative;
    left: 110px;
  }

  .calendarContainer > select {
    width: 126px;
    height: 40px;
    margin: -8px 8px 16px 0px;
    background: $light_grey;
    border-radius: 3px;
    border: 0;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
    }
  }
}

.dateFieldContainer {
  position: relative;
  .dateFields {
    display: flex;
    flex-direction: row;
    .formField {
      width: 45px;
      display: flex;
      flex-direction: column;
      position: relative;

      &.year {
        width: 60px;
      }
      label {
        font-size: 12px;
        margin: 0;
        color: $dark_grey;
      }

      input {
        height: 40px;
        min-height: 40px;
        background: $light_grey;
        border: 0;
        border-radius: 3px;
        padding-left: 10px;
        &:focus {
          outline: none;
        }
      }

      .inputError {
        height: 40px;
        min-height: 40px;
        background: $light_grey;
        border: 0;
        border-radius: 3px;
        padding-left: 10px;
        border: 1px solid $critical;
      }

      .inputDisabled {
        height: 40px;
        min-height: 40px;
        background: $light_grey;
        border: 0;
        border-radius: 3px;
        padding-left: 10px;
        color: $medium_grey;
        font-style: italic;
        cursor: not-allowed;
      }
    }

    .slashSpacer {
      display: flex;
      flex-direction: column;
      margin: 7px 10px;
      justify-content: flex-end;
      .slash {
        font-size: 25px;
      }
    }
  }
  .error {
    color: $critical;
    font-size: 12px;
  }
}

.addPractitionerButton {
  @include btnPrimary;
  font-size: 12px;
  line-height: 14px;
}

.selectArrayHeader {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #323335;
  display: inline-block;
  margin: 32px 0px 18px 0px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-x: auto;
}

.fullSectionContainer {
  width: calc(100vw - 392px);
  z-index: 999;
  max-width: 1080px;
  span.empty {
    color: $medium_grey;
    font-size: 14px;
    line-height: 14px;
    display: block;
    height: 40px;
  }
}

.itemCard {
  min-width: 250px;
  height: 40px;
  background: $light_grey;
  padding: 13px 0px 0px 50px;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  margin-right: 18px;
  margin-bottom: 10px;

  span {
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 24px;
      background: url('../../../assets/icons/person_icon.svg');
      background-size: 14px 24px;
      position: absolute;
      left: 18px;
      top: 8px;
    }
  }

  img {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 12px;
  }
}

.repeatDaysSelector {
  input {
    width: 40px;
    height: 40px;
    background: $light_grey;
    color: $medium_grey;
    border-radius: 3px;
    text-align: center;
    border: 0;
    margin-right: 8px;
    text-transform: capitalize;
    padding: 0;
    cursor: pointer;
    &.dayChecked {
      background: $medium_grey;
      color: $white;
    }
  }
}

.repeatEndingField {
  width: 337px;
  height: 96px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  position: relative;

  label {
    margin-bottom: 12px;
    color: $dark_grey;
  }

  input {
    height: 30px;
    min-height: 30px;
    background: $light_grey;
    border: 0;
    border-radius: 3px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }
}

.editableEmailIcon {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 15px;
  right: 12px;
  cursor: pointer;
}

.helperAnchor {
  position: absolute;
  bottom: 14px;
  right: 17px;
  width: 20px;
  height: 20px;
  padding-left: 4px;
  cursor: pointer;

  .helper {
    height: 13px;
    width: 13px;
    border: 0.5px solid $blue;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;

    img {
      width: 7px;
      height: 11px;
    }
  }
}

.emailTooltip {
  height: 62px;
  width: 243px;
  font-size: 12px;
  line-height: 14px;
}

.checkboxError {
  color: $critical;
  font-size: 12px;
  line-height: 110%;
  position: absolute;
  top: 68px;
  width: 500px;
}

.rowButtons {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  width: 327px;
  justify-content: space-between;
}
