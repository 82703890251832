@import '../../assets/css/variables';
header.compliance-header {
  height: 49px;
  nav.compliance-nav {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    width: 100%;
    float: left;
    border-bottom: 1px solid #a3a6b3;
    height: 49px;
    ul.compliance-tabs {
      //html reset
      position: relative;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
      list-style: none;
      height: 100%;
      transition: all 0.35s ease-out;
      -webkit-transition: all 0.35s ease-out;
      li {
        display: inline-block;
        margin: 0 64px 0 0;
        height: 100%;
        line-height: 1.93rem;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
        &:hover a {
          color: #417eb9;
          cursor: pointer;
          transition: all 0.05s ease-out;
          border-bottom: 2px solid $blue;
        }
        &.active a {
          color: #417eb9;
          background-color: transparent;
          transition: all 0.05s ease-out;
          border-bottom: 2px solid $blue;
        }
        a {
          display: block;
          font-weight: 500;
          font-size: 14px;
          //html reset
          color: #a3a6b3;
          text-decoration: none;
          transition: all 0.35s ease-out;
        }
      }
    }
    .download-button {
      cursor: pointer;
    }
  }
}

@media (max-width: 1440px) {
  header.compliance-header nav.compliance-nav ul.compliance-tabs li {
    margin-right: 30px;
  }
}
