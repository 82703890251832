.noteContainer {
  display: flex;
  flex-direction: column;
  .header {
    height: 76px;
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.3px solid #a3a6b3;
    .title {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
    }
    svg {
      cursor: not-allowed;
    }
  }
  .top {
    height: 45px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 18px 32px;
    border-bottom: 0.3px solid #a3a6b3;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
    }
  }
  .middle {
    padding: 32px;
    height: 600px;
    overflow-y: auto;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #323335;
      margin-bottom: 16px;
    }
    .content {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #323335;
      white-space: pre-line;
    }
  }
  .footer {
    height: 76px;
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    border-top: 0.3px solid #a3a6b3;
    justify-content: flex-end;

    button {
      background: #ffffff;
      border: 2px solid #417eb9;
      box-sizing: border-box;
      border-radius: 3px;
      width: 115px;
      height: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #417eb9;
      cursor: pointer;
    }
  }
}
