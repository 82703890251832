@import 'src/assets/css/variables';

.contentTable {
  width: 100%;
  float: left;
}

.tableHeader {
  height: 46px;
  background: #fff;
  overflow: hidden;
}

.tableHeaderModal {
  height: 60px !important;
  background: #fff;
  overflow: hidden;
  padding-top: 13px;
}

.table {
  box-shadow: 0px 2px 6px #e5e5e5;
  background: #ffffff;
  border-spacing: 0;
}

.noContent {
  width: calc(100% - 36px);
  margin-left: 18px;
  box-shadow: 0px 2px 6px #e5e5e5;
  background: #ffffff;
  border-spacing: 0;
}

.tbody {
  overflow-y: scroll;
  overflow-x: hidden;
}

.tbody::-webkit-scrollbar {
  width: 0;
}

.th {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #323335;
  text-transform: uppercase;
  line-height: 11px;
  align-items: center;
  padding: 18px 0 18px 14px;
  text-align: left;
  vertical-align: middle;
  height: 46px;
  width: 180px;

  &__modal {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #323335;
    text-transform: uppercase;
    line-height: 11px;
    align-items: center;
    padding: 18px 0 18px 18px;
    text-align: left;
    vertical-align: middle;
    height: 60px;
    width: 180px;
  }
}

.th:first-child {
  padding: 18px 14px;
}

.tr.active {
  background: rgba(65, 126, 185, 0.1);
}

.tr {
  height: 47px;
  border-bottom: 0.3px solid #a3a6b3;

  &__md {
    height: 65px;
    border-bottom: 0.3px solid #a3a6b3;
  }

  &__md:last-child {
    border-bottom: 0.3px solid #fff;
  }
}

.td {
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #323335;
  vertical-align: middle;
  transition: all 0.35s ease-out;
  text-align: left;
  box-sizing: border-box;
  height: 100%;
  padding: 18px 14px;
  align-items: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: flex;
  justify-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tr .td:last-child {
  justify-content: flex-end;
}

.tr__md .td:last-child {
  justify-content: flex-end;
}

.td span.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.td span.action {
  font-weight: 600;
  color: #417eb9;
  cursor: pointer;

  &:hover {
    color: #004c8a;
  }
  img {
    margin-right: 5px;
  }
}

.td span.noAction {
  color: #a3a6b3;
}

.td span.status {
  font-style: normal;
  align-items: center;
  display: flex;
  flex-direction: row;
  line-height: 13px;
  &fail {
    color: #ed6f6a;
  }
  &pass {
    color: #6fcf97;
  }
  &miss {
    color: #efbb55;
  }
  &pending {
    color: #a3a6b3;
  }
}

.overflowWrapped {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trackVerticalCustom {
  div {
    background-color: $medium_grey !important;
    left: -6px;
  }
}
