@import 'src/assets/css/variables';

.wizardPagesContainer {
  position: absolute;
  right: 22px;
  display: flex;
  align-items: center;

  div {
    margin-right: 10px;
  }

  .actual {
    height: 15px;
    width: 15px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    border: 1.5px solid $blue;
  }

  .other {
    height: 10px;
    width: 10px;
    background-color: $blue;
    border-radius: 50%;
    display: inline-block;
  }
}
