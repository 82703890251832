@import 'src/assets/css/variables';

.overlay {
  position: fixed;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
  top: -101%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  &.show {
    top: 0;
    opacity: 1;
    .content {
      opacity: 1;
      transition-delay: 0;
      -webkit-transition-delay: 0;
    }
  }

  .btnClose {
    z-index: 1;
    width: 30px;
    height: 30px;
    float: right;
    position: absolute;
    cursor: pointer;
    top: 23px;
    right: 32px;
    background-size: 20px 20px;
    background: url('../../assets/icons/close-modal.svg') no-repeat center center;
  }
  .btnClose img {
    width: 100%;
  }

  .content {
    margin: 15% auto;
    background-color: #ffffff;
    box-shadow: 0 2px 6px #e5e5e5;
    border-radius: 3px;
    width: 70vw;
    //padding: 20px 20px 30px;
    position: relative;
    @media (max-width: 1000px) {
      min-width: auto;
      width: calc(100% - 40px);
    }
    @media (max-width: 750px) {
      padding: 15px;
    }
  }

  .bigContent {
    margin: 15% auto;
    background-color: #ffffff;
    box-shadow: 0 2px 6px #e5e5e5;
    border-radius: 3px;
    width: calc(100% - 312px);
    position: relative;
    @media (max-width: 1280px) {
      width: 90%;
    }
    @media (max-width: 1023px) {
      min-width: auto;
      width: calc(100% - 40px);
    }
    @media (max-width: 750px) {
      padding: 15px;
    }

    &__static {
      margin: 15% auto;
      background-color: #ffffff;
      box-shadow: 0 2px 6px #e5e5e5;
      border-radius: 3px;
      width: calc(100% - 312px);
      min-width: 1128px;
      max-width: 1128px;
      position: relative;
      @media (max-width: 1280px) {
        width: 90%;
      }
      @media (max-width: 1023px) {
        min-width: auto;
        width: calc(100% - 40px);
      }
      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }

  .smallContent {
    margin: 15% auto;
    background-color: #ffffff;
    box-shadow: 0 2px 6px #e5e5e5;
    border-radius: 3px;
    width: calc(100% - 312px);
    min-width: 903px;
    max-width: 903px;
    position: relative;
    @media (max-width: 1280px) {
      width: 90%;
    }
    @media (max-width: 1023px) {
      min-width: auto;
      width: calc(100% - 40px);
    }
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
}

.urContent {
  margin: 15% auto;
  background-color: #ffffff;
  box-shadow: 0 2px 6px #e5e5e5;
  border-radius: 3px;
  width: 1255px;
  min-width: 1255px;
  max-width: 1255px;
  position: relative;
  @media (max-width: 1280px) {
    width: 90%;
  }
  @media (max-width: 1023px) {
    min-width: auto;
    width: calc(100% - 40px);
  }
  @media (max-width: 767px) {
    padding: 15px;
  }
}
